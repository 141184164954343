import React from "react";
import "../HomepageCalculator/Homepagecalculator.css";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { FaLocationArrow } from "react-icons/fa6";
const Homepagecalculator = () => {
  const nav = useNavigate();

  const CalculatorDivs = [
    {
      id: 0,
      name: "Marriage Calculator",
      Link: "/calculator/child-marrige-calculator",
      img: "Website%20Img/marrige.16e2ce5869e9b1dccc3e.png?updatedAt=1718717620009",
    },
    {
      id: 1,
      name: "Pension Calculator",
      Link: "/calculator/pension-calculator",
      img: "Website%20Img/penson.f0574e6fbc69b3e05551.jpg?updatedAt=1718717562719",
    },
    {
      id: 2,
      name: "Human Life Calculator",
      Link: "/calculator/human-life-vlue-calculator",
      img: "Website%20Img/Hlv.ec3a3671038e44bbc4ed.png?updatedAt=1718773285236",
    },
    {
      id: 3,
      name: "Child Education Calculator",
      Link: "/calculator/child-education-calculator",
      img: "Website%20Img/ChildEdu.5fe5e19c83fba64dadf5.png?updatedAt=1718773343063",
    },
    {
      id: 4,
      name: "Retirement Calculator",
      Link: "/calculator/retairment-calculator",
      img: "Website%20Img/Retairment.ba68069c44fffdc32ac8.png?updatedAt=1718773398668",
    },
    {
      id: 5,
      name: "ULIPS Calculator",
      Link: "/calculator/ulip-calculator",
      img: "Website%20Img/ulips.791c925099aa1c8d559b.png?updatedAt=1718773483106",
    },
    {
      id: 6,
      name: "SWP",
      Link: "/calculator/swp-calculator",
      img: "Website%20Img/SWP.c7f282941173bb441f9d.jpg?updatedAt=1718717492672",
    },
    {
      id: 7,
      name: "EMI Calculator",
      Link: "/calculator/emi-calculator",
      img: "Website%20Img/Lumpsum.210a60013b525f0ab708.jpg?updatedAt=1718716978926",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 820 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 632, min: 0 },
      items: 1,
    },
  };


  return (
    <>
<div className="fixwidthDivtext">
      <div className="container">
   
   
            <div className="tile-text-with-text-shadow">
              <h2 >
                CalCulator's
              </h2>


              <div className="commonone">

           
              <h4 className="theheadingcolor">Finalcial Planning in Few Clicks</h4>
              <div className="yellowline"></div>

            <div className="calculatortext">
              <p className="textformat">
                Calculate your future plans with just a few clicks. Discover how
                much money you need for early retirement, plan for your child's
                education with our Marriage and Education Calculator, and
                understand your Human Life Value (HLV). Our tools also include
                calculators for pension planning and ULIPs investment.
              </p>
              <div className="morebuttondivs">
          <Link to={"/calculator"}>All Calculators <FaLocationArrow/></Link>
        </div>
            </div>
            </div>
            </div>

          
     


      <div className="carsouldiv">
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true} // Enable auto-play
          autoPlaySpeed={5000} // Speed for auto-play in milliseconds (5000ms = 5 seconds)
          keyBoardControl={true}
          customTransition="transform 0.5s ease" // Smooth transition
          transitionDuration={500} // Duration for the transition in milliseconds
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {CalculatorDivs.map((data, index) => {
            return (
              <Link to={data.Link}>
                <div className="calculatorsspage">
                  <FastImage
                    url={data.img}
                    width={auto}
                    height={auto}
                    alt={"ca-img"}
                  />
                  <br />
                  <h3>{data.name}</h3>
                </div>
              </Link>
            );
          })}
        </Carousel>

      
      </div>
      </div>
      </div>
    </>
  );
};

export default Homepagecalculator;
