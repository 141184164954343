import React, { useEffect, useState } from "react";
import "./Healthinsurence.css";
import PartnerCompany from "../../../AllPageComponents/PartnerCompany/PartnerCompany";
import FAQ from "../../../AllPageComponents/FAQ/FAQ";
import { Link } from "react-router-dom";
import { LuAlarmClock } from "react-icons/lu";
import Redirect from "../../../AllPageComponents/Rederict";
import HealthBenifits from "../HealthInsurePage/HealthBenifits/HealthBenifits";
import Typeofpolicy from "../../../AllPageComponents/TypeofPolicy/Typeofpolicy";
import NoteWarthyAdvice from "../../../AllPageComponents/NoteWarthyAdvice/NoteWarthyAdvice";
import HealthStepper from ".././HealthStepper/HealthStepper";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import FastImage from "../../../FastImage";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import Understandpolicy from "../../../HomePage/UnderStandpolicy/Understandpolicy";
import ClameReambousment from "../../../AllPageComponents/TypeofPolicy/ClameReambousment/ClameReambousment";
import FinalClaim from "../../../AllPageComponents/TypeofPolicy/ClameReambousment/FilnalClaim/FinalClaim";
import { CiCircleChevRight } from "react-icons/ci";
import DocumentForHealthInsurence from "./HealthInsureComponents/DocumentForHealthInsurence";
import Howtobuybeema from "./HealthInsureComponents/Howtobuyformbeema/Howtobuybeema";
import { Helmet } from "react-helmet-async";
import { getReduxData } from "../../Api/Api";
import Loader from "../../../AllPageComponents/Loader";
import axios from "axios";
import Swal from "sweetalert2";

const Healthinsurence = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const [redone, setredone] = useState(false);
  const [proposerData, setProposerData] = useState();
  const [kycData, setKycData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [healthpath , setHealthPath] = useState('')

  const [healthData, setHealthData] = useState();
  const [uniqueid , setUniqueId] = useState();
  const [extraData , setExtraData] = useState();

  const fetchDashboardData = async (userToken) => {
    try {
      if (!userToken) {
        console.warn("User token not found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/dashboard/dashboard_data`,
        {
          params: { token: userToken },
        }
      );
      if (response.data.status === false) {
        Swal.fire({
          title: "Session Expired",
          text: "Your is Session Expired",
          icon: "error",
          confirmButtonText: "Done",
        });
        localStorage.clear();
        window.location.href = "/";
      }
      return response;
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      let userToken = localStorage.getItem("userToken");
      if (userToken) {
        try {
          let data = await fetchDashboardData(userToken);
          console.log("the data use", data?.data?.data);

          if (data) {
            setUniqueId(data?.data?.data?.searchData[0].unique_id);
            getData(data?.data?.data?.searchData[0].unique_id);
            
          }
          setHealthData(JSON.parse(data?.data?.data?.searchData[0]?.response));
          // setIsLoading(false);
          return JSON.parse(data?.data?.data?.searchData[0]?.response);
        } catch (error) {
      setIsLoading(false);

          console.error("Error fetching policy data", error);
        }
      }
      else{
        setHealthPath("/healthStep1")
        setIsLoading(false)
      }
    };

    fetchData();
  }, []);

  const handelread = () => {
    setredone(!redone);
  };

  const [Raedmore, setRaedmore] = useState(false);
  const Moreread = () => {
    setRaedmore(!Raedmore);
  };

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const initialText =
    "With Beema1, get top-rated health insurance plans online. You can compare online and buy the most suitable insurance plan for you and your family. You can also get your queries answered by our team of experts.";

  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const QNAArray = [
    {
      id: 0,
      Q: "Convenience",
      A: "Getting health insurance online from eliminates all kinds of hassles related to deciding, choosing, and buying health insurance policies. It eliminates the need to visit the offices of different insurance companies or saves you time from meeting the agents of various insurance companies. You have the most convenient and the easiest option to explore, understand, and buy policies from the comfort of your home, at any time that suits you.",
    },
    {
      id: 1,
      Q: "Easy Comparison",
      A: "The online platform of Beema1 is quite user-friendly which allows you to compare multiple insurance plans, side by side on one single platform from different providers. This enables you to analyze your medical coverage, premiums, and benefits more efficiently, helping and making your life easier to make an informed decision.",
    },
    {
      id: 2,
      Q: "Transparent Information",
      A: "Online platforms provide detailed information about the most useful medical insurance plans available with the most reputed insurers in India, including coverage details, terms & conditions, and exclusions. This transparency empowers and helps you to understand the policy thoroughly before making a wise decision..",
    },
    {
      id: 3,
      Q: "Cost Savings",
      A: "When you buy affordable health insurance policies online, you can save money, such as lower premiums since discounts are only available online. Additionally, you can save your valuable time and travel expenses.",
    },
    {
      id: 4,
      Q: "Instant Policy Issuance",
      A: "Online purchasing often leads to faster policy issuance. Once you ve selected a health insurance policy and completed the necessary documentation and payment, you can receive your policy document instantly via emai",
    },
    {
      id: 5,
      Q: "Accessible Customer Support",
      A: "Many online insurance platforms offer customer support services through online chat options, email, or phone. This means you can easily reach out to our customer support for assistance or clarification regarding your policy, claim processes, or any other queries you have.",
    },
  ];

  const HowToBuyHealthInsurence = [
    {
      step: 1,
      title: "Provide Your Details",
      description:
        "Enter your information to get instant access to competitive insurance quotes. Easily compare insurance policy premiums from the top-rated insurers.",
    },
    {
      step: 2,
      title: "Compare Quotes & Select The Right Health Insurance Plan",
      description:
        "Compare plans based on features and costs aligned with your needs, and your budget, and then choose the best-fit plan for yourself.",
    },
    {
      step: 3,
      title: "Pay and Get Policy Within Minutes",
      description:
        "Buy your policy online and receive it instantly in your email inbox.",
    },
    {
      step: 4,
      title: "Get Your Policy",
      description:
        "Get your policy – Once you make the payment towards your insurance policy, your policy will be issued immediately in no time, only if there are no underwriting requirements.",
    },
    {
      step: 5,
      title: "Constant Customer Support",
      description:
        "Our customer service team is dedicated to helping you with any queries.",
    },
  ];

  const healthcover = [
    [
      {
        id: 1,
        title: "Pre-existing Illness",
        description:
          "Health insurance covers pre-existing illness after a waiting period. It's essential to intimate and confirm this period to your insurer.",
      },
      {
        id: 2,
        title: "Pre and Post-Hospitalisation",
        description:
          "Before hospitalization, expenses like blood and urine tests are covered. After discharge, medication costs are included for the patient's well-being.",
      },
      {
        id: 3,
        title: "Preventive Health Check-ups",
        description:
          "Many plans cover vaccinations, screenings, and wellness check-ups at no extra cost.",
      },
      {
        id: 4,
        title: "Ambulance Expenses",
        description:
          "Most plans cover ambulance costs, though coverage varies among insurers.",
      },
      {
        id: 5,
        title: "Maternity Cover",
        description:
          "Medical insurance includes expenses related to childbirth.",
      },
      {
        id: 6,
        title: "Day-care Procedures",
        description:
          "Coverage is provided for procedures requiring less than 24 hours of hospital stay.",
      },
      {
        id: 7,
        title: "Home Care",
        description:
          "Treatment administered at home by a medical practitioner is covered.",
      },
      {
        id: 8,
        title: "AYUSH Treatment",
        description:
          "Health insurance in India also covers alternative therapies like homeopathy and Ayurveda.",
      },
      {
        id: 9,
        title: "Mental Healthcare Cover",
        description:
          "Following IRDAI guidelines, coverage for mental health issues such as bipolar disorder and depression is now mandatory.",
      },
    ],
  ];

  const KeepInMind = [
    {
      id: 1,
      name: "The Age",
      des: "Considering that age plays a pivotal role in health insurance coverage while purchasing a health insurance policy, the age of all the family members in a family matters. Especially in family floater plans, where insurance premiums shoot up based on the age of the eldest family member. Make sure to review the age restrictions when selecting a health insurance plan. Some insurance plans accept applicants of babies as young as 91 days old and up to 60 years of age, while others set minimum and maximum entry ages between 25 and 50. Certain plans have no age restrictions, providing you with more flexibility and options in your choice.",
    },
    {
      id: 2,
      name: "The Right Combination of Premium and Coverage",
      des: "Opting for a medical insurance policy with the lowest premium may seem interesting, but it is also important to calculate, speculate, and consider the advantages and disadvantages of buying a plan with lower medical coverage. A lower premium can be beneficial if it offers comprehensive coverage at an affordable cost; otherwise, it would mean compromising on the quality of the health coverage. To make the best choice, investigate the pros and cons behind the reduced premium to ensure that you don’t compromise on the coverage part. Watch out for additional clauses like co-payment, deductibles, and sub-limits, as they might increase your expenses during a claim.",
    },
    {
      id: 3,
      name: "Policy Term",
      des: "The length of the policy term in a health insurance plan is also considered a vital factor for deciding on the premium amount. You can opt for various policy terms such as 1 year, 2 years, or 3 years. Higher health coverage offered usually requires a higher premium.",
    },
    {
      id: 4,
      name: "Lifestyle",
      des: "There is a popular saying, 'I am my own sailor sailing my boat.' Life is a gift that depends on our lifestyle, influenced by both good and bad habits. Bad Habits may lead us to various health hazards, and health hazards will result into higher premium.",
    },
    {
      id: 5,
      name: "Claim Settlement Ratio",
      des: "When selecting an insurer, prioritize those with a strong track record in claim settlements, especially for medical claims. Companies with high claim settlement ratios indicate reliability and should be chosen over those with flashy discounts.",
    },
    {
      id: 6,
      name: "Cashless Network Hospitals",
      des: "Health insurance providers partner with network hospitals for cashless treatment during emergencies. This saves you from heavy paperwork during admissions and claims. Always verify the list of network hospitals with your insurer.",
    },
    {
      id: 7,
      name: "Scope of Coverage",
      des: "Understanding your health insurance policy before buying is crucial. Knowing what's covered can prevent unexpected costs during a claim. Learn about terms like copayment and deductibles to avoid surprises.",
    },
    {
      id: 8,
      name: "Co-Payment Clause",
      des: "The co-payment clause represents the percentage of the claim amount you pay, while the insurer covers the rest. Review any applicable co-payment clauses that could affect your claim payout before finalizing your health insurance policy.",
    },
  ];

  const checlkistarray = [
    {
      id: 1,
      question: "What is the bonus for not claiming in a policy year?",
      answer:
        "If you don’t make a claim in a particular year, the insurance company may offer you extra coverage. Some insurers provide this bonus for each year you pass without a claim.",
    },
    {
      id: 2,
      question:
        "Are there restrictions on room rent in health insurance policies?",
      answer:
        "Yes, many health insurance policies have restrictions on room rent. Some insurers may limit your choice of room, and exceeding this limit could result in additional charges.",
    },
    {
      id: 3,
      question: "Should I split my hospital bills?",
      answer:
        "It is generally not advisable to split your hospital bills. If your insurer has a co-payment clause, you may have to pay a percentage of the total bill for each claim.",
    },
    {
      id: 4,
      question: "What are sub-limits as per disease?",
      answer:
        "Sub-limits refer to restrictions on how much coverage is available for each specific disease, even if your overall cover is substantial.",
    },
    {
      id: 5,
      question: "What is pre and post-hospitalization cover?",
      answer:
        "Pre and post-hospitalization cover includes expenses related to diagnostics and treatment that occur before and after hospital admission, which can add up significantly.",
    },
    {
      id: 6,
      question: "Why is a low waiting period important?",
      answer:
        "A low waiting period is crucial if you have pre-existing conditions, as it determines how long you'll have to wait before your insurer starts covering claims related to those issues.",
    },
    {
      id: 7,
      question: "What is a restoration benefit?",
      answer:
        "Restoration benefit allows your medical cover to be restored after a claim, ensuring that you have coverage available if another family member needs treatment.",
    },
    {
      id: 8,
      question: "What is the daycare treatment benefit?",
      answer:
        "Daycare treatment benefit covers medical procedures that last less than 24 hours, which some insurers may not cover.",
    },
    {
      id: 9,
      question: "Is there a free health check-up offered every year?",
      answer:
        "Some insurance policies include annual free health check-ups, which can be beneficial for proactive health management.",
    },
    {
      id: 9,
      question: "Does insurance cover alternative treatments?",
      answer:
        "Check with your insurer to see if they cover alternative treatments such as Ayurveda, Yunani, or Siddha in your medical insurance plan.",
    },
    {
      id: 10,
      question: "Bonus in case you don’t claim in that policy year",
      answer:
        "Suppose there’s no claim from your end in that particular year, and the insurance company offers you extra cover. Your health insurer keeps offering you extra coverage every year you pass without a claim; wouldn't that be cool? There are insurance policies in the market that offer a lot of good bonus coverage. Better to check if your policy provides these benefits too.",
    },
    {
      id: 11,
      question: "Restrictions on room and room rents",
      answer:
        "All health insurance policies provide different restrictions on room rent. Some insurers have a limit on room rent. Some insurance companies simply won’t allow you to select a room of your choice. If you infringe this limit, they will make you pay extra for every limit service reduced in the room and not just the room rent. So, be wise and have clarity from your insurer regarding room rent limitations before you buy a policy.",
    },
    {
      id: 12,
      question: "Do not split your hospital bills",
      answer:
        "The insurer may poke you to consider a co-payment clause, in that case, you will be forced to pay a part of the bill each time you make a claim. It could be 10%,20%, or 30% of the total bill. Therefore, availing the co-pay might not be the optimum option, except you have no choice",
    },
    {
      id: 13,
      question: "Your sub-limits as per disease",
      answer:
        "It happens when your insurer offers you a huge cover for a modest and reasonable fee, only to include restrictions on how much of this cover will be available for each and every disease.",
    },
    {
      id: 14,
      question: "Pre and Post-Hospitalization Cover",
      answer:
        "Nobody falls sick immediately. You will likely have to go through a series of diagnostic tests before you are admitted to the hospital. Once you are discharged, you will start worrying about medication and medical investigations. These costs will add up, so it’s best to pick insurance policies that cover pre & post-hospitalization care.",
    },
    {
      id: 15,
      question: "Aim for a low waiting period",
      answer:
        "In case of any pre-existing diseases including (diabetes, blood pressure, or thyroid-related illnesses), you will have to wait for a fixed period before your insurer starts covering claims. It's wiser to pick a policy with a shorter waiting period.",
    },
    {
      id: 16,
      question: "Cover for restoration benefit",
      answer:
        "Imagine you buy a health insurance policy for your family. If you are hospitalized and run out of cover, a restoration benefit can help. This feature restores your cover each time you make a claim, providing peace of mind.",
    },
    {
      id: 17,
      question: "Daycare Treatment Benefit",
      answer:
        "Procedures like dialysis, chemotherapy, or quick surgeries may last less than 24 hours. Some insurance companies might not cover these claims because they don’t include 'daycare treatments'.",
    },
    {
      id: 18,
      question: "Free Health Check-Up Every Year",
      answer:
        "Some insurance policies pay for your annual health check-ups. If you are health-conscious, these free check-ups can be very beneficial.",
    },
    {
      id: 19,
      question: "Cover For The Alternative Treatments",
      answer:
        "If you consider alternative treatments like Ayurveda, Yunani, or Siddha, check if your insurer covers these options in your medical insurance plan.",
    },
  ];
  const [showAll, setShowAll] = useState(false);

  // Assuming checlkistarray is your array of data
  const displayedData = showAll ? checlkistarray : checlkistarray.slice(0, 10);

  const handleSeeMore = () => {
    setShowAll(!showAll);
  };

  const getData = async (unique_id) => {
    try {
      let response = await getReduxData(unique_id);
   

      if(response){
        let proposerinfo = response?.data?.data[0]?.proposal_data;
        let kycinfo = response?.data?.data[0]?.kyc_data;
        let extrainfo = (response?.data?.data[0]?.extra_data)

        if(!extrainfo){
          setIsLoading(false)

          // alert("extrainfo empty");
          return
        }

        // if(kycinfo && proposerinfo){
        //   setHealthPath(`/summarypage?req_id=${unique_id}&c_id=${extrainfo?.c_id}&plan_id=${extrainfo?.plan_id}`)
        // }
        // else if(!kycinfo){
        //   setHealthPath(`/kyc/?req_id=${unique_id}&c_id=${extrainfo?.c_id}&plan_id=${extrainfo?.plan_id}`)
        // }
        // else if(!proposerinfo){
        //   setHealthPath(`/proposal/?req_id=${unique_id}&c_id=${extrainfo?.c_id}&plan_id=${extrainfo?.plan_id}`)
        // }
        // else{
        //   setHealthPath("/healthStep1")
        // }
        if (kycinfo && Object.keys(kycinfo).length > 0 && proposerinfo && Object.keys(proposerinfo).length > 0) {
          setHealthPath(`/summarypage?req_id=${unique_id}&c_id=${extrainfo?.c_id}&plan_id=${extrainfo?.plan_id}`);
      } else if (!kycinfo || Object.keys(kycinfo).length === 0) {
          setHealthPath(`/kyc?req_id=${unique_id}&c_id=${extrainfo?.c_id}&plan_id=${extrainfo?.plan_id}`);
      } else if (!proposerinfo || Object.keys(proposerinfo).length === 0) {
          setHealthPath(`/proposal?req_id=${unique_id}&c_id=${extrainfo?.c_id}&plan_id=${extrainfo?.plan_id}`);
      } else {
          setHealthPath("/healthStep1");
      }
      
      }
      // proposerData && kycData ? `/summarypage?req_id=${unique_id}&c_id=${extraData?.c_id}&${extraData?.plan_id}`: !proposerData ?`proposal/?req_id=${unique_id}&c_id=${extraData?.c_id}&${extraData?.plan_id}`: (!proposerData)?`kyc/?req_id=${unique_id}&c_id=${extraData?.c_id}&${extraData?.plan_id}`


      if (response?.data?.data[0]?.proposal_data) {
        setProposerData(response.data.data[0].proposal_data);

        setIsLoading(false);
      } else if (response?.data?.data[0]?.kyc_data) {
        setKycData(response.data.data[0].kyc_data);
        setIsLoading(false);
      } 
      else if(response?.data?.data[0]?.extra_data){
        setExtraData(response?.data?.data[0]?.extra_data);
        setIsLoading(false);

      }
      else {
        // isLoading(false);
        setIsLoading(false);

      }
    } catch (error) {
      setIsLoading(false);

      console.log("Error loading data:", error);
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : (<>

      <div>
        <Helmet>
          <title>Health Insurance</title>
          {/* <meta name="description" content="A brief description of your page for SEO." /> */}
          <meta
            name="keywords"
            content="medical insurance, Get health insurance, affordable health insurance"
          />
        </Helmet>

        <section className="mainnargin-insurance">
          <div className="container">
            <Redirect />
            <div className="row insurance-row-one">
              <div className="col-sm-12 col-md-4 col-lg-4 mian-one ">
                <div className="healthdiscription">
                  {/* <h1><title>Buy Best Health Insurance Policy Online</title></h1> */}
                  <h1>Buy Health Insurance in lowest premium @ Rs.10/day</h1>
                  <div>
                    <span style={{ textAlign: "justify" }}>{initialText}</span>
                  </div>
                </div>
                <div className="insurance-btn-set">
                  <button
                    type="button"
                    className="reminderbutton"
                    data-toggle="modal"
                    data-target="#reminderpopup"
                  >
                    {" "}
                    <LuAlarmClock
                      fontSize={"13pt"}
                      style={{ margin: "0 5px", color: "#FBA71B" }}
                    />{" "}
                    Set Reminder
                  </button>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 side-image">
                <FastImage
                  url={
                    "Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"
                  }
                  width={auto}
                  height={auto}
                  src={
                    "Website%20Img/mascot_image1678942204.png?updatedAt=1718776425936"
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
                <div className="form-main">
                  <form id="insurance_form_get_plan">
                    <div className="d-flex align-items-center form-input">
                      <h6>
                        Compare &amp; Buy Customized Health Plans starting at
                        just
                        <span className="specialprice">Rs ₹257/month*</span>
                      </h6>
                      { (proposerData || kycData) && (localStorage.getItem('userToken'))&& <div className="blue-btn">
                        <Link
                          // to={proposerData && kycData ? `/summarypage?req_id=${unique_id}&c_id=${extraData?.c_id}&${extraData?.plan_id}`: !proposerData ?`proposal/?req_id=${unique_id}&c_id=${extraData?.c_id}&${extraData?.plan_id}`: (!proposerData)?`kyc/?req_id=${unique_id}&c_id=${extraData?.c_id}&${extraData?.plan_id}`:"/healthStep1"}
                          // to={"/healthStep1"}
                          to={healthpath}
                          className="open-halth-insurance-health-plan"
                        >
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "white",
                              fontWeight: "400",
                            }}
                          >
                           Proceed to Payment
                          </button>{" "}
                          <FastImage
                            url={
                              "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                            }
                            width={auto}
                            height={auto}
                            src={
                              "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                            }
                          />
                        </Link>
                      </div>
                       }
                     <div className="blue-btn">
                        <Link
                        
                          to={"/healthStep1"}
                          // to={healthpath}
                          className="open-halth-insurance-health-plan"
                        >
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "white",
                              fontWeight: "400",
                            }}

                            onClick={()=>{
                              const keysToRemove = [
                                "District", "Gender", "InsuredDetail", "PED", "PinCode", "ProposalName",
                                "Proposalphonenumber", "State", "ageadded", "profile_name", 
                                "selectedType", "show_price", "uid"
                              ];
                              
                              keysToRemove.forEach(key => localStorage.removeItem(key));
                            }}

                          >
                            Get Your Quotes
                          </button>{" "}
                          <FastImage
                            url={
                              "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                            }
                            width={auto}
                            height={auto}
                            src={
                              "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                            }
                          />
                        </Link>
                      </div>
                      

                      <p className="insurance-text-light">
                        By clicking, I agree to{" "}
                        <Link to={"/termsandcondition"}>
                          *terms &amp; conditions
                        </Link>{" "}
                        and <Link to={"/privacy-policy"}>privacy policy</Link>.
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="inbulitmargine"></div>

        {/* <Understandpolicy /> */}

        <div className="fixwidthDivtext">
          <div className="container">
            <h4 className="healthHeading">
              Comfort of Buying Health Insurance Online Through Beema1
            </h4>
            <div className="yellowline"></div>
            <p className="normaltext">
              Although medical science is improving and developing with time,
              healthcare costs keep going up. That's why it's important to get a
              good health insurance plan. Some major reasons why people buy
              health insurance plans are:
            </p>
            <div className="row">
              <div className="col-md-12">
                {QNAArray.map((data) => {
                  return (
                    <>
                      <span className="normaltext">
                        <span className="specialprice">{data.Q}</span>
                        {data.A}
                      </span>
                      <br />
                      <br />
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="fixwidthDivtext">
          <div className="container">
            <h4 className="healthHeading">
              Keep In Mind Before You Buy Health Insurance Policy
            </h4>
            <div className="yellowline"></div>
            <p className="normaltext">
              A health insurance document is a contract agreement between you
              (the policyholder) and the insurance company (the insurer). The
              insurer settles down commitments based on the agreement to assist
              you with medical expenses during any medical emergencies.
              Therefore, choose your health insurance policy meticulously and
              the company wisely
            </p>
            <div className="row">
              <div className="col-md-6 col-sm-12 d-flex justify-content-center   align-items-center">
                <div>
                  {KeepInMind.map((data) => {
                    return (
                      <Accordion
                        expanded={expanded === `panel${data.id}`}
                        onChange={handleChange(`panel${data.id}`)}
                        key={data.id}
                      >
                        <AccordionSummary
                          expandIcon={<MdOutlineExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className="accordingback"
                        >
                          <li className="listcol">
                            <IoMdAddCircle
                              style={{
                                color: "#2959B8",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                              fontSize={"13pt"}
                            />
                            {data.name}
                          </li>
                        </AccordionSummary>
                        <AccordionDetails className="AnwsarSpan">
                          {data.des}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center   align-items-center">
                <FastImage
                  url="/Website%20Img/African%20family-pana.png?updatedAt=1727152314816"
                  width={auto}
                  height={auto}
                  className="fmailimagegifs"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fixwidthDivtext">
          <div className="container">
            <h4 className="healthHeading">
              What Does Health Insurance Usually Cover?{" "}
            </h4>

            <div className="yellowline"></div>
            <p className="normaltext">
              In India, most companies offer coverage for the following:
            </p>
            <div className="row">
              <div className="col-md-12">
                <div className="tableoverflow">
                  <table className="health-coverage-table">
                    <thead>
                      <tr>
                        <th className="table-header">Health Coverage</th>
                        <th className="table-header">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-cell">Hospitalization Coverage</td>
                        <td className="table-cell">
                          This includes expenses for inpatient care, surgeries,
                          treatments, and room charges if the patient stays in
                          the hospital for more than 24 hours
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">Pre-existing Illness</td>
                        <td className="table-cell">
                          Health insurance covers pre-existing illness after a
                          waiting period. It's significantly important to notify
                          and confirm this period to your insurer
                        </td>
                      </tr>

                      <tr>
                        <td className="table-cell">
                          Pre and Post-Hospitalisation
                        </td>
                        <td className="table-cell">
                          Before hospitalization, expenses like blood and urine
                          tests are covered. After discharge, medication costs
                          are included for the patient's well-being.
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">
                          Preventive Health Check-ups
                        </td>
                        <td className="table-cell">
                          Many plans cover vaccinations, screenings, and
                          wellness check-ups at no extra cost.
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">Ambulance Expenses</td>
                        <td className="table-cell">
                          Most plans cover ambulance costs, though coverage
                          varies among insurers.
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">Maternity Cover</td>
                        <td className="table-cell">
                          Medical insurance includes expenses related to
                          childbirth.
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">Day-care Procedures</td>
                        <td className="table-cell">
                          Coverage is provided for procedures requiring less
                          than 24 hours of hospital stay.
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">Home Care</td>
                        <td className="table-cell">
                          Treatment administered at home by a medical
                          practitioner is covered.
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">AYUSH Treatment</td>
                        <td className="table-cell">
                          Health insurance in India also covers alternative
                          therapies like homeopathy and Ayurveda.
                        </td>
                      </tr>
                      <tr>
                        <td className="table-cell">Mental Healthcare Cover</td>
                        <td className="table-cell">
                          Following IRDAI guidelines, coverage for mental health
                          and psychological welfare issues such as bipolar
                          disorder and depression is now mandatory
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Howtobuybeema />

        <div className="fixwidthDivtext">
          <div className="container">
            <h2 className="theheadingcolor">
              Eligibility Criteria To Buy Health A Insurance Plan{" "}
            </h2>
            <div className="yellowline"></div>
            <div className="row">
              <div className="col-md-12">
                <p className="normaltext">
                  <span className="specialprice">Age:-</span>
                  The minimum age criteria to get health insurance for adults is
                  18 years, and the maximum age limit is 65 years. For children,
                  the entry age is 90 days to 18 years. When you buy a health
                  insurance policy at a young age, it is cost-effective since
                  insurers might not require medical exams and you will be
                  charged lower premiums as your mortality costs will be on the
                  lower side because of your age.
                </p>
                <p className="normaltext">
                  <span className="specialprice">
                    Pre-existing Medical Condition:-
                  </span>
                  A pre-existing medical condition refers to an illness that
                  exists before buying a health insurance policy. If an
                  individual has a condition like diabetes or a heart condition
                  before purchasing insurance, the insurer may impose certain
                  conditions before approving the coverage. It's advisable to
                  disclose pre-existing conditions rather than hiding them and
                  opt for a medical cover that includes the coverage of
                  pre-existing diseases. However, this might result in a higher
                  premium in certain instances. Some insurers may also propose a
                  co-payment arrangement in such scenarios, where the
                  policyholder splits the medical expenses with the company.
                </p>

                <p className="normaltext">
                  <span className="specialprice">
                    Being Transparent Is Must:-
                  </span>
                  When you plan to avail of a health insurance plan, the advisor
                  of your insurance provider will hand over a list of
                  questionnaires related to your existing health conditions and
                  your inclination towards any kind of genetic health conditions
                  that might impact your health. Instead of being crafty and
                  secretive by not disclosing your smoking status to your
                  insurance provider, you will be only welcoming worse
                  situations regarding your health insurance claim. The
                  consequences are devastating resulting in policy cancellation
                  and claim rejection. Make your mind genuinely transparent
                  concerning disclosing your smoking habits to the insurance
                  company. If you plan to keep your smoking habit a secret and
                  expect that your health insurance provider will never be able
                  to find out the truth, then you are wrong. If for any reason
                  you are hospitalized for any treatment, you need to be
                  truthful with your consulting doctor. If you are not,then that
                  hospitalization-related claim-raising process will prove you
                  wrong and your claim may be declined by your provider.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ClameReambousment/> */}

      <DocumentForHealthInsurence />

      <div className="fixwidthDivtext">
        <div className="container">
          <h4 className="healthHeading">
            Health Insurance Premium Calculator{" "}
          </h4>
          <div className="yellowline"></div>
          <p className="normaltext">
            Estimate the value of your medical insurance plan effortlessly with
            a medical insurance premium calculator . This online tool quickly
            calculates the premium of your health plan, making the process
            hassle-free. Best of all, it's free to use and lets you compare
            different plans to find one that suits your needs and budget.
          </p>
          <p className="normaltext">
            The premium is calculated based on factors like BMI, age, lifestyle,
            income, gender, and the number of people covered. Make sure to
            provide accurate details for an accurate premium calculation.
          </p>

          <p className="normaltext">
            Here's how to use the medical insurance premium calculator online:
          </p>

          <div className="row">
            <div className="col-md-5">
              <FastImage
                url="/Website%20Img/Calculator-bro.png?updatedAt=1727240441252"
                className="fmailimagegifs"
                width={auto}
                height={auto}
              />
            </div>
            <div className="col-md-7">
              {/* <p  className="normaltext"><span>1.&nbsp;</span>Visit Beema1's official website and click on 'Health.'</p>
              <p  className="normaltext"><span>2.&nbsp;</span>Enter your personal information such as name, age, gender, city, mobile number, and the number of people to be covered. Then, click 'Check Premium</p>
              <p  className="normaltext"><span>3.&nbsp;</span>You'll see a list of suitable health plans with their premiums displayed.</p>
              <p  className="normaltext"><span>4.&nbsp;</span> Choose the plan that you need and it should fit in your budget and offer the coverage you need from the available options.</p> */}

              <div className="boxstepper">
                <div className="numberStepper">1</div>
                <div className="descriptionContainer">
                  Visit Beema1's official website and click on 'Health'
                </div>
              </div>
              <div className="boxstepper">
                <div className="numberStepper">2</div>
                <div className="descriptionContainer">
                  Enter your personal details click , 'Check Premium
                </div>
              </div>
              <div className="boxstepper">
                <div className="numberStepper">3</div>
                <div className="descriptionContainer">
                  List of suitable health plans and their premiums displayed
                </div>
              </div>
              <div className="boxstepper">
                <div className="numberStepper">4</div>
                <div className="descriptionContainer">
                  Select a Budget Firendly Plan Offering the Coverage You need{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixwidthDivtext">
        <div className="container">
          <h4 className="healthHeading">
            Factors Affect Health Insurance Premium
          </h4>
          <div className="yellowline"></div>

          <p className="normaltext">
            As medical technology progresses, healthcare expenses are on the
            rise. Health insurance plays a vital role in managing these costs,
            providing financial security in unforeseen circumstances like
            serious illness or accidents. Here's how insurers determine policy
            costs:
          </p>

          <div className="row">
            <div className="col-md-12">
              <p className="normaltext">
                <span className="specialprice">Medical History:-</span>
                &nbsp;Your health background significantly influences your
                premium. While some insurers ask for medical tests, others
                consider current health status, lifestyle risks, and family
                medical history.
              </p>
              <p className="normaltext">
                <span className="specialprice">Gender and Age:-</span>
                &nbsp;Premiums increase with age and lifestyle. It’s a
                million-dollar advice to all youngsters to purchase early health
                insurance coverage when the premium is low. Elderly individuals
                are bound to pay higher premiums due to increased health risks.
              </p>
              <p className="normaltext">
                <span className="specialprice">Policy Term:-</span>
                &nbsp;Longer-term policies may have higher premiums but often
                offer discounts. Comparing options using online calculators can
                help find the best solution.
              </p>
              <p className="normaltext">
                <span className="specialprice">Type of Plan:-</span>
                &nbsp;Coverage types affect premiums; broader coverage generally
                means higher premiums.
              </p>
              <p className="normaltext">
                <span className="specialprice">No Claim Bonus (NCB):-</span>
                &nbsp;Not filing for health insurance claims can make you earn
                NCB discounts of 5% to 50% on renewal premiums. No Claim Bonus
                is a profitable perquisite offered to the policyholder by
                his/her insurance provider where the sum assured is increased by
                a fixed percentage. This is a perk with notable benefits for the
                policyholders and on the part of the insurers for a claimless
                policy year, indicating good health hold-up by the policyholder{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="fixwidthDivtext">
        <div className="container">
          <h4 className="healthHeading">Tax Benefits of Health Insurance</h4>

          <div className="yellowline"></div>
          <div className="row">
            <div className="col-md-12">
              <p className="normaltext">
                Under Section 80D of the Income Tax Act, of 1961, Hindu
                Undivided Families are eligible for tax benefits. These benefits
                extend to premiums paid for oneself and family members,
                encompassing spouses, dependent children, and parents.
              </p>
              <p className="normaltext">
                Additionally, deductions from total income can be claimed for
                premiums paid towards top-up and <b>critical illness health</b>{" "}
                insurance plans, in addition to regular insurance policies.
              </p>
              <p className="normaltext">
                For a detailed breakdown of deductions available to individual
                taxpayers in different scenarios, please refer to the table
                provided below:
              </p>

              <div className="tableoverflow">
                <table className="taxBenefitTable">
                  <thead>
                    <tr className="taxBenefitTable-header">
                      <th className="taxBenefitTable-headerCell">Situations</th>
                      <th className="taxBenefitTable-headerCell">
                        Health Insurance Purchased For
                      </th>
                      <th className="taxBenefitTable-headerCell">
                        Deduction for Insurance Premium Under Section 80D
                      </th>
                      <th className="taxBenefitTable-headerCell">
                        Claim Limit for Preventive Health Checkup
                      </th>
                      <th className="taxBenefitTable-headerCell">
                        The Ceiling of Mediclaim Deductions Available Under
                        Section 80D
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        id: 1,
                        situation: "Self (below 60 years of age) + family",
                        premiumDeduction: "₹25,000",
                        claimLimit: "₹5,000",
                        ceiling: "₹50,000",
                      },
                      {
                        id: 2,
                        situation: "Parents (below 60 years of age)",
                        premiumDeduction: "₹25,000",
                        claimLimit: "₹5,000",
                        ceiling: "₹75,000",
                      },
                      {
                        id: 3,
                        situation: "Self (above 60 years of age) + family",
                        premiumDeduction: "₹50,000",
                        claimLimit: "₹7,000",
                        ceiling: "₹1,00,000",
                      },
                      {
                        id: 4,
                        situation: "Parents (above 60 years of age)",
                        premiumDeduction: "₹25,000",
                        claimLimit: "₹5,000",
                        ceiling: "Either ₹25,000 or ₹50,000",
                      },
                      {
                        id: 5,
                        situation: "Hindu Undivided Family (HUF)",
                        premiumDeduction: "₹25,000",
                        claimLimit: "₹5,000",
                        ceiling: "₹25,000",
                      },
                    ].map((item) => (
                      <tr key={item.id} className="taxBenefitTable-row">
                        <td className="taxBenefitTable-cell">{item.id}</td>
                        <td className="taxBenefitTable-cell">
                          {item.situation}
                        </td>
                        <td className="taxBenefitTable-cell">
                          {item.premiumDeduction}
                        </td>
                        <td className="taxBenefitTable-cell">
                          {item.claimLimit}
                        </td>
                        <td className="taxBenefitTable-cell">{item.ceiling}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixwidthDivtext">
        <div className="container">
          <h4 className="healthHeading">Waiting Period In Health Insurance</h4>
          <div className="yellowline"></div>
          <div className="row">
            <div className="col-md-12">
              <p className="normaltext">
                Even with a <b>health plan</b> in place, you may not receive
                full benefits for certain conditions due to health insurance
                waiting periods. This means you'll only be eligible for coverage
                within a specific time frame, starting from the inception of the
                policy.
              </p>

              <p className="normaltext">
                <span className="specialprice">Initial Waiting Period:</span>
                During this cooling-off period, policyholders must wait for a
                specific number of days before claiming any benefits. Most plans
                have a waiting period ranging from 1 month to 90 days.
              </p>

              <p className="normaltext">
                <span className="specialprice">
                  Pre-Existing Diseases (PED) Waiting Period:
                </span>
                This waiting period applies to certain conditions known at the
                time of purchasing the policy. Pre-existing diseases, including
                thyroid, hypertension, and diabetes, typically have a waiting
                period of 1-4 years as per the regulations set by the Insurance
                Regulatory and Development Authority of India (IRDAI).
              </p>

              <p className="normaltext">
                <span className="specialprice">
                  Waiting Period for Specific Diseases:
                </span>
                Certain conditions like hernia, tumors, ENT disorders, cancer,
                strokes, and cardiac issues have specific waiting periods,
                varying between insurers and stated in the policy provisions.
              </p>

              <p className="normaltext">
                <span className="specialprice">Maternity Waiting Period:</span>
                For those planning to start a family, maternity coverage can be
                clubbed to a standard health policy. However, benefits are only
                accessible after a waiting period of 2 to 4 years. Waiting
                periods are meaningfully designed to deter individuals from
                hiding or misinforming the insurance companies while taking any
                insurance policies for improper reasons. They help prevent
                situations where individuals purchase insurance plans after
                diagnosis without disclosing the condition to the insurer,
                promoting ethical behavior within the entire system of the
                Insurance World
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="fixwidthDivtext">
        <div className="container">
          <h4 className="healthHeading">
            Health Insurance Checklist in Detail
          </h4>
          <div className="yellowline"></div>
          <p className="normaltext">
            Just to avoid your precious time browsing through hundreds of health
            insurance policies we have created this easy-to-handle checklist for
            you to understand what to look for in a good health policy. This
            checklist is considered to be very vital in selecting a decent
            health insurance plan
          </p>

          <div className="row">
            <div className="col-md-6">
              <FastImage
                url="/Website%20Img/Checklist-pana.png?updatedAt=1727249784622"
                width={auto}
                height={auto}
                className="checklistimage"
              />
            </div>
            <div className="col-md-6">
              <>
                {displayedData.map((data) => {
                  return (
                    <Accordion
                      expanded={expanded === `panel${data.id}`}
                      onChange={handleChange(`panel${data.id}`)}
                      key={data.id}
                    >
                      <AccordionSummary
                        expandIcon={<MdOutlineExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="accordingback"
                      >
                        <li className="listcol">
                          <IoMdAddCircle
                            style={{
                              color: "#2959B8",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                            fontSize={"13pt"}
                          />
                          {data.question}
                        </li>
                      </AccordionSummary>
                      <AccordionDetails className="AnwsarSpan">
                        {data.answer}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <div className="redbtn">
                  <button onClick={handleSeeMore} className="Readmorebtn">
                    {showAll ? "See Less" : "See More"}
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* <Typeofpolicy/> */}

      <div className="fixwidthDivtext">
        <div className="container">
          <h4 className="healthHeading">
            Documents Required For Health Insurance Claim Reimbursement
          </h4>

          <div className="yellowline"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="tableoverflow">
                <table className="documents-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Valid ID</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Completed claim form</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Doctor's hospitalization recommendation</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Original pharmacy bills and prescriptions</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Indoor case papers</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Ambulance receipt</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>FIR copy if necessary</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Policy details</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Any other documents as requested</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table className="cashless-claim-table">
                <thead>
                  <tr>
                    <th className="table-header">
                      Additional Documents for Cashless Claim
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table-data">Valid ID with photo</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <FinalClaim />

      <div className="fixwidthDivtext">
        <div className="container">
          <div className="row">
            {/* Advantages Section */}
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="adv-dis-title">
                <h4>Advantage</h4>
              </div>
              <div className="left-sid-adv adv-dis-box">
                {[
                  {
                    number: 1,
                    title: "Combat Medical Inflation",
                    description:
                      "Despite the escalating prices of healthcare, health insurance coverage can assist you in paying your medical expenditures, including pre-and post-hospitalisation costs.",
                  },
                  {
                    number: 2,
                    title: "Cashless Hospitalisation Facility",
                    description:
                      "You can use a Cashless Hospitalisation Facility at any of your insurance provider's network hospitals by filing a Cashless Claim.",
                  },
                  {
                    number: 3,
                    title: "Get Tax Benefits",
                    description:
                      "This allows you to better arrange your finances by allowing you to deduct the cost of your health insurance from your taxable income under section 80D of the Income Tax Act.",
                  },
                  {
                    number: 4,
                    title: "Affording The Best Medical Care",
                    description:
                      "It enables you to pay for the highest medical care, allowing you to concentrate on getting better.",
                  },
                ].map((adv) => (
                  <div className="adv-dis-boxs" key={adv.number}>
                    <div className="adv-number">{adv.number}</div>
                    <div className="adv-dis-text-box">
                      <h6>{adv.title}</h6>
                      <p>{adv.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Disadvantages Section */}
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="adv-dis-title">
                <h4>Disadvantage</h4>
              </div>
              <div className="right-sid-dis adv-dis-box">
                {[
                  {
                    number: 1,
                    title: "Waiting Period",
                    description:
                      "Pre-existing diseases (PED) have a minimum waiting period of 3 to 4 years that varies for different insurance companies. During this period, you will not be able to avail of insurance benefits.",
                  },
                  {
                    number: 2,
                    title: "Age Factor",
                    description:
                      "As age increases, the chances of obtaining health insurance coverage reduces, or premiums become too high to afford.",
                  },
                  {
                    number: 3,
                    title: "Critical Illness",
                    description:
                      "Critical illness is not covered by any insurance company from day one. It has a waiting period of 6 months and above.",
                  },
                  {
                    number: 4,
                    title: "Erosion of Savings",
                    description:
                      "One hospitalization can make a deep hole in your pocket, as treatment costs these days are exponentially high.",
                  },
                ].map((disadv) => (
                  <div className="adv-dis-boxs" key={disadv.number}>
                    <div className="adv-number">{disadv.number}</div>
                    <div className="adv-dis-text-box">
                      <h6>{disadv.title}</h6>
                      <p>{disadv.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <HealthStepper /> */}

      <PartnerCompany />
      <div className="fixwidthDivtext">
        <div className="container">
          <div className="tile-text-with-text-shadow">
            <h2>Health Benefits</h2>

            <div className="row">
              <div className="col-md-12">
                <p className="textformat">
                  Looking for the best insurance deals? Look no further! We've
                  partnered with top insurance providers to offer exclusive
                  deals and discounts. So don't miss the chance to save big on
                  your insurance premiums.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FAQ />
      </div>
      </>
      )}
    </>
  );
};

export default Healthinsurence;
