import React, { useEffect, useState } from 'react';
import '../Dashbord/Dashbord.css';
import { MdOutlineAutoGraph } from "react-icons/md";
import { AiOutlineFileProtect } from "react-icons/ai";
import { LuAlarmClock } from "react-icons/lu";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Pagination } from '@mui/material';
import { RxCross2 } from "react-icons/rx";
import { MdMenuOpen } from "react-icons/md";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { PiFilePdfDuotone } from "react-icons/pi";
import { Helmet } from 'react-helmet-async';

const Dashbordpolicy = () => {
  const [policyData, setPolicyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); 
  const userToken = localStorage.getItem('userToken');
  const [policyType, setpolicyType] = useState('all');
  const [rerender , setReRender] = useState(false)



  let companyArray = {
    "ZUNO" :"Zuno",
    "TATA AIG Insurance" : "TATA AIG Insurance",
    "HDFC ERGO General Insurance Company Ltd" : "HDFC ERGO General Insurance Company Ltd",
    "ICICI" : "icici",
    "HDFC" : "HDFC",
    'CARE' : "CareHealthinsurance"

  }

  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/dashboard/policies`, {
          params: { token: userToken },
        });
        if (response.data.status === false) {
          localStorage.clear();
          window.location.href = '/';
          return;
        }
        
        // Set policy data based on policy type
        switch (policyType) {
          case 'health':
            setPolicyData(response.data.data.health_data || []);
            break;
          case 'term':
            setPolicyData(response.data.data.life_data || []);
            break;
          case 'motor':
            setPolicyData(response.data.data.motor_data || []);
            break;
          default: // For "all" or any unexpected case
            setPolicyData([
              ...(response.data.data.health_data || []),
              ...(response.data.data.life_data || []),
              ...(response.data.data.motor_data || []),
            ]);
            break;
        }
      } catch (error) {
        console.error('Error fetching policy data:', error);
        // Optionally, set an error state or show an error message to the user
      }
    };
  
    if (userToken) {
      fetchPolicyData();
    } else {
      console.warn('User token not found in local storage');
      // Optionally redirect or notify the user
    }
  }, [userToken, policyType]); // Include policyType to trigger data fetching when it changes
  



  console.log("the one policydata",policyData)

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = policyData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const LogoutData = () =>{
  localStorage.clear();
  window.location.href = '/';
}


const [searchQuery, setSearchQuery] = useState("");
const [SearchData, setSearchData] = useState([])

const GlobalSearchFunction = (e) => {
  setSearchQuery(e.target.value.toLowerCase()); 
  const filteredData = policyData.filter((policy) => {
  const searchText = searchQuery.toLowerCase(); 
    const searchKeys = ["app_id","company", "created_at", "customerId","exp_date","id","policy_num","policy_type","premium_amt","proposal_no","propser_pan","unique_id","user_payment_id","users_id","users_veh_id"];
    return searchKeys.some((key) =>
      policy[key] && policy[key].toString().toLowerCase().includes(searchText)
    );
  });

    setSearchData(filteredData)

};


console.log('the policydata',policyData)
// ... rest of your code

const [ShowSideBar, setShowSideBar] = useState(true)

const ShowMenuBar = () =>{
  setShowSideBar(!ShowSideBar)
}


const [open, setOpen] = React.useState(false);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setOpen(open);
};


const checKingStatus = (item)=>{

  let data = JSON.stringify({
    // "plan_code": item.plan_id,
    // "proposalNum": item.proposalNo,
    // "unique_id": item.unique_id

    "plan_code": item.plan_code,
    "proposalNum": item.proposal_no,
    "unique_id": item.unique_id


  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
   
    // url:  `${process.env.REACT_APP_BASE_URL}health/policy_check`,
    url:  `${process.env.REACT_APP_BASE_URL}health/policy_check/${(item.company).toLowerCase()}`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });


}

// export const updateAddonApi = async (unique_id, updatedUserdata) => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}health/updatequickquote_single_com/${unique_id}`,
//       updatedUserdata,
//       { headers: { Token: encryption() } }
//     );
//     const responseData = response.data;

//     return responseData;
//   } catch (error) {
//     console.error("AddOnApi Error:", error);
//     throw error;
//   }
// };









const list = () => (
  <Box
  sx={{ width: 250 }}
  role="presentation"
  onClick={toggleDrawer(false)}
  onKeyDown={toggleDrawer(false)}
>
<div className='crossdiv'>
<button><RxCross2/></button>
</div>
  <ul className="underullist">

    <li className="activeDashbord">
      <Link to="/dashbord">
        <MdOutlineAutoGraph /> &nbsp; Dashbord
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-policy">
        <AiOutlineFileProtect /> &nbsp; Policy
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/setup-reminder">
        <LuAlarmClock /> &nbsp; Setup Reminders
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-Claim">
        <MdHealthAndSafety /> &nbsp; Clame Mangement
      </Link>
    </li>
    {/* <li className="deactiveDashbord" onClick={LogoutData}>
      <IoIosLogOut /> &nbsp; Logout
    </li> */}
  </ul>
</Box>
);


// console.log('The Comming Url is',currentItems)

  return (
    <>
<div className="dashbordmargin"></div>
    <div className="maincontainer">
      {/* Sidebar Container Start Hear */}
      <div className={ShowSideBar ? "sidebarse" : ''}>
      <div className="subsidebar">
      <ul>
      <Link to={'/dashbord'}> <li className='deactiveDashbord' > <MdOutlineAutoGraph/> Dashbord</li></Link>
      <Link to={'/dashbord-policy'}> <li className="activeDashbord">  <AiOutlineFileProtect/> Policy</li></Link>
      <Link to={'/setup-reminder'}><li className="deactiveDashbord"><LuAlarmClock/> Setup Reminders</li></Link> 
      <Link to={'/dashbord-Claim'}> <li className="deactiveDashbord"> <MdHealthAndSafety/> Claim Mangement</li></Link>
      {/* <li className="deactiveDashbord" onClick={LogoutData}><a><IoIosLogOut/>  Logout</a></li> */}
      </ul>
      </div>
      </div>
      {/* Sidebar Container End Hear */}

      {/* Dashbord Container Start Hear */}
      <div className="dashbordcontainer">
        <div className="underpolicy">
   
    
        
        <div className="textpolicy">
        <div className="buttonnavbar">
    
    <Button onClick={toggleDrawer(true)}><MdMenuOpen style={{fontSize:"25pt"}}/></Button>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    
    </div>
            <span>Policy</span>
             <div className="policytype_tab">
              <div className={policyType === "all" ? "hero_policy_tab" : "hero_policy_deactive_tab"} onClick={()=>{setpolicyType('all')}}>All</div>
              <div className={policyType === "health" ? "hero_policy_tab" : "hero_policy_deactive_tab"} onClick={()=>{setpolicyType('health')}}>Health</div>
              <div className={policyType === "motor" ? "hero_policy_tab" : "hero_policy_deactive_tab"} onClick={()=>{setpolicyType('motor')}}>Motor</div>
              <div className={policyType === "term" ? "hero_policy_tab" : "hero_policy_deactive_tab"} onClick={()=>{setpolicyType('term')}}>Term</div>
             </div>

          </div>
          <Helmet>
        <title>Policy</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
    

      <div className="table-container">
            <table className="policy-table">
              <thead>
                <tr>
                <th>First Name</th>
                <th>Last Name</th>
                  <th>Policy ID</th>
                  <th>Purchase date</th>
                  <th>Policy Status</th>
                  <th>Expiry date</th>
                  <th>Company Details</th>
                  <th>Premium</th>
                  <th>Year</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {searchQuery === "" ? (
                  currentItems.map((data, index) => {
                    // Convert UTC time to local time
                    const utcDate = new Date(data.created_at);
                    const expdat = new Date(data.exp_date);

                    // Options for formatting date and time
                    const dateTimeOptions = {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    };

                    // Format date and time
                    const formattedDateTime = utcDate.toLocaleString("en-US", dateTimeOptions);
                    const erparydatetime = expdat.toLocaleString("en-US", dateTimeOptions);

                  

                    function formatCurrencyInIndianRupees(premiumAmt) {
                      // Round the premium amount using Math.round
                      const roundedPremium = Math.round(premiumAmt);

                      // Format the rounded amount in Indian rupee format
                      const formattedPremium = roundedPremium.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      });

                      return formattedPremium;
                    }

                    // Example usage:
                    // const premiumAmount = {data.premium_amt};
                    const formattedIndianRupees = formatCurrencyInIndianRupees(data.premium_amt);

                    const lowercaseCompany = data.company.toLowerCase();
                    const capitalizedCompany = lowercaseCompany.charAt(0).toUpperCase() + lowercaseCompany.slice(1);
                    let pdfUrl;

                    console.log("data.company", data)
                    // console.log("data.policystatus", data.policy_status);

                    if(data.p_type == "health"){
                  
                      if(data.type == "online"){
                        pdfUrl = `${process.env.REACT_APP_MAIN_URL}/healthpdf/${companyArray[data.company] ? 
                          companyArray[data.company] : (data.company).toLowerCase() }/${data.pdf_url}`;
                      }
                      else{
                        pdfUrl = `${process.env.REACT_APP_CMS_URL}/healthpdf/${companyArray[data.company] ? 
                          companyArray[data.company] : (data.company).toLowerCase() }/${data.pdf_url}`;
                      }
                     

                    }
                    else if(data.p_type == "motor"){

                      if(data.type == "online"){
                        pdfUrl = `${process.env.REACT_APP_MAIN_URL}/motorpdf/${companyArray[data.company] ? 
                          companyArray[data.company] : (data.company).toLowerCase() }/${data.pdf_url}`;
                      }
                      else{
                        pdfUrl = `${process.env.REACT_APP_CMS_URL}/motorpdf/${companyArray[data.company] ? 
                          companyArray[data.company] : (data.company).toLowerCase() }/${data.pdf_url}`;
                      }
                     

                    }
                    else if(data.p_type == "term"){

                      if(data.type == "online"){
                        pdfUrl = `${process.env.REACT_APP_MAIN_URL}/termpdf/${companyArray[data.company] ? 
                          companyArray[data.company] : (data.company).toLowerCase() }/${data.pdf_url}`;
                      }
                      else{
                        pdfUrl = `${process.env.REACT_APP_CMS_URL}/termpdf/${companyArray[data.company] ? 
                          companyArray[data.company] : (data.company).toLowerCase() }/${data.pdf_url}`;
                      }

                   
                    }

                    const onButtonClick = async () => {
                      try {
                          const response = await fetch(pdfUrl);
                          if (!response.ok) {
                              throw new Error(`HTTP error! Status: ${response.status}`);
                          }
                          const blob = await response.blob();
                          const link = document.createElement("a");
                          link.href = window.URL.createObjectURL(blob);
                          link.download = "document.pdf"; // specify the filename for download
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                      } catch (error) {
                          console.error('Error fetching or downloading the PDF:', error);
                      }
                  };
                    return (
                      <tr key={index}>
                        <td>{data?.first_name}</td>
                        <td>{data?.last_name}</td>
                        <td>{data.policy_num}</td>
                        <td>{formattedDateTime}</td>
                        <td>{data.policy_status == "Done" || "success" ? <span className='policy-issued'>Issued</span> : 
                        data.policy_status}</td>
                        <td>{erparydatetime}</td>
                        <td>{data.company}</td>
                        <td>{formattedIndianRupees}</td>
                        <td>Year</td>
                        {data.pdf_url ? <td className='renewbutton'>
                        {/* <button onClick={onButtonClick}>Download Policy</button>  */}
                        <a href={pdfUrl}  target="_blank"   rel="noopener noreferrer" download className='beemadownload'><PiFilePdfDuotone fontSize={'22pt'}/></a>
                        </td> : 
                        <button
                        className="table-button" 
                        onClick={()=>{
                          checKingStatus(data)
                        }}>
                        Check Status
                      </button>
                      }
                      </tr>
                    );
                  })
                ) : (
                  policyData.map((data, index) => {
                    const utcDate = new Date(data.created_at);
                    const expdat = new Date(data.exp_date);

                    // Options for formatting date and time
                    const dateTimeOptions = {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    };

                    // Format date and time
                    const formattedDateTime = utcDate.toLocaleString("en-US", dateTimeOptions);
                    const erparydatetime = expdat.toLocaleString("en-US", dateTimeOptions);

                    function formatCurrencyInIndianRupees(premiumAmt) {
                      // Round the premium amount using Math.round
                      const roundedPremium = Math.round(premiumAmt);

                      // Format the rounded amount in Indian rupee format
                      const formattedPremium = roundedPremium.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      });

                      return formattedPremium;
                    }

                    // Example usage:
                    // const premiumAmount = {data.premium_amt};
                    const formattedIndianRupees = formatCurrencyInIndianRupees(data.premium_amt);
                    //console.log(formattedIndianRupees); // Output: ₹1,235
                    console.log("data policy ststus", data);
                    return (
                      <tr key={index}>
                        <td>{data.policy_num}</td>
                        <td>{formattedDateTime}</td>
                        <td>{data.policy_status =="success" || "Done" ? <span className='policy-issued'>Issued</span> :data.policy_status }</td>
                        <td>{erparydatetime}</td>
                        <td>{data.company}</td>
                        <td>{formattedIndianRupees}</td>
                        <td>Year</td>
                        <td className='renewbutton'><button>Download</button></td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>

            {/* Pagination */}
          
          </div>

          <div className="padenationdiv" hidden={policyData.length < 7}>
              <Pagination 
                count={Math.ceil(policyData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)} 
                color="primary" 
              />
            </div>
        </div>




      </div>





      
      {/* Dashbord Container End Hear */}
    </div>
    </>
  )
}

export default Dashbordpolicy;
