import React from 'react'
import './ImportenceOfInsurence.css';


const ImportenceOfInsurence = () => {
  return (
      
    <div className="fixwidthDivtext">
    <div className='container'>
    <div className="textheads home-font tile-text-with-text-shadow">
    <h2>Why Insurance Important ?</h2>
    </div>
     <div class="commonone">
      <div>
      <h4 className='theheadingcolor'>Why Insurance Is Important For Everyone ? </h4>
      <div className="yellowline"></div>
      <span className='textformat'>Insurance plans are beneficial to anyone looking to protect their family, assets/property, and themselves from financial risks or losses. The insurance plan will help you pay for medical emergencies, hospitalizations, contraction of any illnesses, major treatments, and medical care required in the future. So, compare and buy insurance plans to safeguard your family.</span>
      </div>
     </div>
    </div>
    </div>

  )
}

export default ImportenceOfInsurence