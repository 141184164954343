import React, { useState, useEffect } from 'react';
import '../SIP/SIP.css';
import Slider from '@mui/material/Slider';
import '../BMI/BMI.css';
import { Link } from 'react-router-dom';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import { Helmet } from 'react-helmet-async';

const BMI = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [bmi, setBMI] = useState(null);
  const [selectGender, setSelectGender] = useState('Male');
  const [height, setHeight] = useState(4);
  const [inchi, setInchi] = useState(8);
  const [weight, setWeight] = useState(51);
  const [age, setAge] = useState(24);
  const [datase, setData] = useState(0);
  const [bmiCondition, setBMICondition] = useState('');

  const setGender = (data) => {
    setSelectGender(data);
  };

  const handleHeight = (event) => {
    setHeight(event.target.value);
  };

  const handleInchi = (event) => {
    setInchi(event.target.value);
  };

  const handleWeight = (event) => {
    setWeight(event.target.value);
  };

  const handleAge = (event) => {
    setAge(event.target.value);
  };
  const handleCalculate = () => {
    if (weight <= 0 || (height <= 0 && inchi < 0)) {
      alert('Please enter valid values for weight and height.');
      return;
    }
  
    let totalHeightInCM = (parseFloat(height) * 30.48) + (parseFloat(inchi) * 2.54);
    let heightInMeters = totalHeightInCM / 100;
  
    console.log("Height in CM:", totalHeightInCM);
    console.log("Height in Meters:", heightInMeters);
    console.log("Weight:", weight);
  
    const bmi = weight / (heightInMeters * heightInMeters);
    setBMI(bmi.toFixed(2));
  };
  
  useEffect(() => {
    const bmiData = Math.ceil(bmi);
    if (bmiData < 14) {
      setData(2.8);
    } else if (bmiData >= 14 && bmiData <= 16) {
      setData(10.8);
    } else if (bmiData >= 17 && bmiData <= 18) {
      setData(30);
    } else if (bmiData >= 18 && bmiData <= 19) {
      setData(35);
    } else if (bmiData >= 20 && bmiData <= 22) {
      setData(40);
    } else if (bmiData >= 23 && bmiData <= 24) {
      setData(60);
    } else if (bmiData >= 25 && bmiData <= 26) {
      setData(70);
    } else if (bmiData >= 27 && bmiData <= 28) {
      setData(80);
    } else if (bmiData >= 28 && bmiData <= 30) {
      setData(100);
    } else if (bmiData >= 30 && bmiData <= 31) {
      setData(110);
    } else if (bmiData >= 32 && bmiData <= 33) {
      setData(120);
    } else if (bmiData >= 33 && bmiData <= 34) {
      setData(130);
    } else if (bmiData >= 35 && bmiData <= 36) {
      setData(140);
    } else if (bmiData >= 37 && bmiData <= 38) {
      setData(150);
    } else if (bmiData >= 39 && bmiData <= 40) {
      setData(160);
    } else if (bmiData >= 41 && bmiData <= 42) {
      setData(170);
    } else if (bmiData >= 43 && bmiData <= 45) {
      setData(180);
    } else {
      setData(180);
    }
  }, [bmi]);

  useEffect(() => {
    if (bmi < 18) {
      setBMICondition("Underweight");
    } else if (bmi >= 19 && bmi < 30) {
      setBMICondition("Normal");
    } else if (bmi >= 31 && bmi < 39) {
      setBMICondition("Overweight");
    } else if (bmi > 40) {
      setBMICondition("Obese");
    }
  }, [bmi]);

  const underWeightStyle = {
    color: '#cd0000',
    backgroundColor: '#fbc5c5',
    padding: '15px 0px',
    width: '99%',
    fontFamily: 'Poppins',
    borderRadius: '10px',
    margin: '20px auto',
    fontSize: "10pt"
  };

  const normalStyle = {
    color: "#00460d",
    backgroundColor: "#00813738",
    padding: "15px 0px",
    width: "99%",
    fontFamily: 'Poppins',
    borderRadius: "10px",
    margin: "20px auto",
    fontSize: "10pt"
  };

  const overWeightStyle = {
    color: "#837a0b",
    backgroundColor: "#a2a94038",
    padding: "15px 0px",
    width: "99%",
    fontFamily: 'Poppins',
    borderRadius: "10px",
    margin: "20px auto",
    fontSize: "10pt"
  };

  const obesityStyle = {
    color: "#830c0c",
    backgroundColor: "d9666638",
    padding: "15px 0px",
    width: "99%",
    fontFamily: 'Poppins',
    borderRadius: "10px",
    margin: "20px auto",
    fontSize: "10pt"
  };

  const dataAsString = `${datase}deg`;

  return (
    <>
      <Helmet>
        <title>Online BMI Calculator</title>
        <meta name="keywords" content="bmi calculator online, online lump sum calculator" />
      </Helmet>

      <section className="margincalculator"></section>
      <div className="headingcalculator">
        <div className="headerminwidth">
          <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp; <Link to={"/calculator"}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{ color: 'green' }}>BMI calculator</span></p>
          <span className="titlecalculator">BMI <span className='Mainheading'>Calculator</span></span>
          <br />
          <span className="CalculatorDiscription">A BMI calculator assesses body fat using height and weight, indicating if someone is underweight, normal weight, overweight, or obese, aiding in understanding associated health risks</span>
        </div>
      </div>

      <div className="calculatorhead">
        <div className="underdiv">
          <div className="calculatorse">
            <div className="calculaterse main">
              <div className="calculator">
                <div className='bmigenderheader'>
                  <span className={selectGender === "Male" ? 'genderspan' : 'deactivategender'} onClick={() => setGender('Male')}>Male</span>
                  <span className={selectGender === "Female" ? 'genderspan' : 'deactivategender'} onClick={() => setGender('Female')}>Female</span>
                </div>

                <div className="amountdives">
                  <p>Height(ft)</p>
                  <input type="text" value={height} onChange={handleHeight} />
                </div>
                <div className="amountselectordiv">
                  <Slider value={height} onChange={handleHeight} min={1} max={10} step={1} aria-label="Default" valueLabelDisplay="auto" />
                </div>

                <div className="amountdives">
                  <p>Height (inchi)</p>
                  <input type="text" onChange={handleInchi} value={inchi} />
                </div>
                <div className="amountselectordiv">
                  <Slider value={inchi} onChange={handleInchi} aria-label="Default" valueLabelDisplay="auto" min={1} max={12} step={1} />
                </div>

                <div className="amountdives">
                  <p>Weight</p>
                  <input type="text" onChange={handleWeight} value={weight} />
                </div>
                <div className="amountselectordiv">
                  <Slider value={weight} onChange={handleWeight} aria-label="Default" valueLabelDisplay="auto" min={1} max={150} />
                </div>

                <div className="amountdives">
                  <p>Age</p>
                  <input type="text" onChange={handleAge} value={age} />
                </div>
                <div className="amountselectordiv">
                  <Slider value={age} onChange={handleAge} aria-label="Default" valueLabelDisplay="auto" min={1} max={100} />
                </div>

                <div className="bmidata">
                  <button onClick={handleCalculate} disabled={age === ''}>Calculate</button>
                </div>
              </div>

              <div className="BMIGraph">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="300px" height="163px" viewBox="0 0 300 163">
                  <defs>
                    <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
                      <polygon points="0 0, 10 3.5, 0 7" />
                    </marker>
                  </defs>
                  <g transform="translate(18,18)" style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: 12 }}>
                    <defs>
                      <marker id="arrowhead" markerWidth={10} markerHeight={7} refX={0} refY="3.5" orient="auto">
                        <polygon points="0 0, 10 3.5, 0 7" />
                      </marker>
                      <path id="curvetxt1" d="M-4 140 A140 140, 0, 0, 1, 284 140" style={{ fill: "none" }} />
                      <path id="curvetxt2" d="M33 43.6 A140 140, 0, 0, 1, 280 140" style={{ fill: "none" }} />
                      <path id="curvetxt3" d="M95 3 A140 140, 0, 0, 1, 284 140" style={{ fill: "none" }} />
                      <path id="curvetxt4" d="M235.4 33 A140 140, 0, 0, 1, 284 140" style={{ fill: "none" }} />
                    </defs>
                    <path d="M0 140 A140 140, 0, 0, 1, 6.9 96.7 L140 140 Z" fill="#bc2020" />
                    <path d="M6.9 96.7 A140 140, 0, 0, 1, 12.1 83.1 L140 140 Z" fill="#d38888" />
                    <path d="M12.1 83.1 A140 140, 0, 0, 1, 22.6 63.8 L140 140 Z" fill="#ffe400" />
                    <path d="M22.6 63.8 A140 140, 0, 0, 1, 96.7 6.9 L140 140 Z" fill="#008137" />
                    <path d="M96.7 6.9 A140 140, 0, 0, 1, 169.1 3.1 L140 140 Z" fill="#ffe400" />
                    <path d="M169.1 3.1 A140 140, 0, 0, 1, 233.7 36 L140 140 Z" fill="#d38888" />
                    <path d="M233.7 36 A140 140, 0, 0, 1, 273.1 96.7 L140 140 Z" fill="#bc2020" />
                    <path d="M273.1 96.7 A140 140, 0, 0, 1, 280 140 L140 140 Z" fill="#8a0101" />
                    <path d="M45 140 A90 90, 0, 0, 1, 230 140 Z" fill="#fff" />
                    <circle cx={140} cy={140} r={5} fill="#666" />
                    <g style={{ paintOrder: "stroke", stroke: "#fff", strokeWidth: 2 }}>
                      <text x={25} y={111} transform="rotate(-72, 25, 111)">16</text>
                      <text x={30} y={96} transform="rotate(-66, 30, 96)">17</text>
                      <text x={35} y={83} transform="rotate(-57, 35, 83)">18.5</text>
                      <text x={97} y={29} transform="rotate(-18, 97, 29)">25</text>
                      <text x={157} y={20} transform="rotate(12, 157, 20)">30</text>
                      <text x={214} y={45} transform="rotate(42, 214, 45)">35</text>
                      <text x={252} y={95} transform="rotate(72, 252, 95)">40</text>
                    </g>
                    <g style={{ fontSize: 14 }}>
                      <text><textPath xlinkHref="#curvetxt1">Underweight</textPath></text>
                      <text><textPath xlinkHref="#curvetxt2">Normal</textPath></text>
                      <text><textPath xlinkHref="#curvetxt3">Overweight</textPath></text>
                      <text><textPath xlinkHref="#curvetxt4">Obesity</textPath></text>
                    </g>
                    <line className='rotatingLine' x1={140} y1={140} x2={65} y2={140} stroke="#666" strokeWidth={3} markerEnd="url(#arrowhead)" style={{ transformOrigin: '140px 140px', animation: 'rotateLine 2s linear forwards' }}>
                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="30 140 140" to={`${dataAsString} 140 140`} dur="5s" fill="freeze" repeatCount="1" keyTimes="0;1" keySplines="0.42, 0, 0.58, 1" />
                    </line>
                    <text x={67} y={120} style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>BMI = {bmi || 0}</text>
                  </g>
                </svg>
                <div hidden={bmi < 5}>
                  {bmi < 16 && <h6 style={underWeightStyle}>Your BMI is {bmi} which is {bmiCondition}</h6>}
                  {(bmi >= 16 && bmi < 17) && <h6 style={normalStyle}>Your BMI is {bmi} which is {bmiCondition}</h6>}
                  {(bmi >= 19 && bmi < 25) && <h6 style={normalStyle}>Your BMI is {bmi} which is {bmiCondition}</h6>}
                  {(bmi >= 26 && bmi < 30) && <h6 style={overWeightStyle}>Your BMI is {bmi} which is {bmiCondition}</h6>}
                  {(bmi >= 31 && bmi < 35) && <h6 style={overWeightStyle}>Your BMI is {bmi} which is {bmiCondition}</h6>}
                  {(bmi >= 32 && bmi < 40) && <h6 style={obesityStyle}>Your BMI is {bmi} which is {bmiCondition}</h6>}
                  {bmi > 40 && <h6 style={obesityStyle}>Your BMI is {bmi} which is {bmiCondition}</h6>}
                </div>
              </div>
            </div>
          </div>
          <div className="resultse">
            <Calculatorlist />
          </div>
        </div>
      </div>
      <style>{`
        @keyframes rotateLine {
          from { transform: rotate(0deg); }
          to { transform: rotate(${dataAsString}); }
        }
        .rotatingLine { transform-origin: 140px 140px; animation: rotateLine 2s linear forwards; }
      `}</style>
    </>
  );
};

export default BMI;