import React, { useState, useEffect } from "react";
import "../Bond/BondCalculator.css";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import Slider from "@mui/material/Slider";
import { Link } from "react-router-dom";
import Redirect from "../../../AllPageComponents/Rederict";
import Chart from 'react-apexcharts';
import { Helmet } from "react-helmet-async";
var convertRupeesIntoWords = require('convert-rupees-into-words');

const BondCalculator = () => {
  const [currentValue, setCurrentValue] = useState(95);
  const [faceValue, setFaceValue] = useState(100);
  const [couponRate, setCouponRate] = useState(5);
  const [yearsToMaturity, setYearsToMaturity] = useState(5);
  const [ytm, setYtm] = useState(0);
  const [TotalInvestmentAmount, setTotalInvestmentAmount] = useState(50000);
  const [SimpleInterest, setSimpleInterest] = useState(0);
  const [CompoundInterestr, setCompoundInterestr] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function calculateYTM(faceValue, couponRate, yearsToMaturity, currentValue) {
    const annualCouponPayment = (couponRate / 100) * faceValue;
    const ytm = ((annualCouponPayment + (faceValue - currentValue) / yearsToMaturity) / ((faceValue + currentValue) / 2)) * 100;
    return ytm;
  }

  function calculateCompoundInterest(principal, rate, time) {
    const compoundRate = rate / 100;
    const amount = principal * Math.pow((1 + compoundRate), time);
    return amount.toFixed(2);
  }

  const handleCalculateYTM = () => {
    const ytmValue = calculateYTM(faceValue, couponRate, yearsToMaturity, currentValue);
    setYtm(ytmValue);

    // Calculate the simple interest maturity amount
    const maturityAmount = parseFloat(TotalInvestmentAmount) * Math.pow(1 + couponRate / (100 * 4), 4 * yearsToMaturity);
    setSimpleInterest(maturityAmount);

    // Calculate the future value with compound interest using the total investment amount
    const futureValue = calculateCompoundInterest(TotalInvestmentAmount, ytmValue, yearsToMaturity);
    setCompoundInterestr(futureValue);
  };

  const textAmount = convertRupeesIntoWords(Math.round(CompoundInterestr));

  return (
    <div>
         <Helmet>
        <title>Bond calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
                    <section className="margincalculator"></section>
      <div className="headingcalculator">
        <p>
          Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;
          <Link to={"/calculator"}>calculator</Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;
          <span style={{ color: "green" }}>Bond calculator</span>
        </p>
        <h1 className="titlecalculator">Bond <span className='Mainheading'>Calculator</span></h1>
     
      </div>
<div className="underbonddivs">


      <div className="underdiv">
        <div className="calculatorse">
          <div className="calculaterse">
          <div className="outerdiv_chnage">
            <div className="calculator">
              <div className="onecalculator">
                <div className="amountdives">
                  <p>Total Invested Amount</p>
                  <input
                    type="number"
                    value={TotalInvestmentAmount}
                    onChange={(event) => setTotalInvestmentAmount(parseFloat(event.target.value))}
                  />
                </div>
                <div className="amountselectordiv">
                  <Slider
                    min={0}
                    max={100000000}
                    step={100}
                    aria-label="Current Price"
                    valueLabelDisplay="auto"
                    value={TotalInvestmentAmount}
                    onChange={(event, newValue) => setTotalInvestmentAmount(newValue)}
                  />
                </div>
              </div>

              <div className="onecalculator">
                <div className="amountdives">
                  <p>Current Bond Price</p>
                  <input
                    type="number"
                    value={currentValue}
                    onChange={(event) => setCurrentValue(parseFloat(event.target.value))}
                  />
                </div>
                <div className="amountselectordiv">
                  <Slider
                    min={0}
                    max={100000}
                    step={100}
                    aria-label="Current Price"
                    valueLabelDisplay="auto"
                    value={currentValue}
                    onChange={(event, newValue) => setCurrentValue(newValue)}
                  />
                </div>
              </div>

              <div className="onecalculator">
                <div className="amountdives">
                  <p>Face Value</p>
                  <input
                    type="number"
                    value={faceValue}
                    onChange={(event) => setFaceValue(parseFloat(event.target.value))}
                  />
                </div>
                <div className="amountselectordiv">
                  <Slider
                    min={1}
                    max={1000}
                    step={1}
                    aria-label="Face Value"
                    valueLabelDisplay="auto"
                    value={faceValue}
                    onChange={(event, newValue) => setFaceValue(newValue)}
                  />
                </div>
              </div>

              <div className="onecalculator">
                <div className="amountdives">
                  <p>Coupon Rate (%)</p>
                  <input
                    type="number"
                    value={couponRate}
                    onChange={(event) => setCouponRate(parseFloat(event.target.value))}
                  />
                </div>
                <div className="amountselectordiv">
                  <Slider
                    min={1}
                    max={100}
                    step={0.1}
                    aria-label="Coupon Rate"
                    valueLabelDisplay="auto"
                    value={couponRate}
                    onChange={(event, newValue) => setCouponRate(newValue)}
                  />
                </div>
              </div>

              <div className="onecalculator">
                <div className="amountdives">
                  <p>Years to Maturity</p>
                  <input
                    type="number"
                    value={yearsToMaturity}
                    onChange={(event) => setYearsToMaturity(parseFloat(event.target.value))}
                  />
                </div>
                <div className="amountselectordiv">
                  <Slider
                    min={1}
                    max={100}
                    aria-label="Years to Maturity"
                    valueLabelDisplay="auto"
                    value={yearsToMaturity}
                    onChange={(event, newValue) => setYearsToMaturity(newValue)}
                  />
                </div>
              </div>

              <div className="amountselectordiv">
                <button className="btn btn-primary" onClick={handleCalculateYTM}>
                  Calculate
                </button>
              </div>

            </div>

           <div className="undergraphdivest">


<Chart
        type='donut'
        width={400}
        height={445}
        series={[TotalInvestmentAmount, CompoundInterestr*1]}
        options={{
          labels:['Invested Amount', 'Return Value']
        }}
        />
           </div>

 
            </div>
            <div className="row resultdesk">
<div className="calculateamountdiv">
                <p className="textbond">
                  Future Investment Value:{" "}
                  <strong className="textblues">
                    {Math.round(parseFloat(CompoundInterestr)).toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR"
                    })}
                  </strong>
                </p>
                <p className="textamount">{textAmount}</p>
                <p className="ytmtext">Yield to Maturity: &nbsp; <strong className="textblues">{ytm.toFixed(3)}%</strong></p>
              </div>

</div>
          </div>
        </div>
        <div className="resultse">
          <Calculatorlist />
        </div>
      </div>

        
</div>
    </div>
  );
};

export default BondCalculator;
