import React, { useEffect, useMemo, useState } from "react";
import StepSlider from "../../commonComponents/StepSlider";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import locations from "../../../locations.json";
import { useSpring, animated } from "@react-spring/web";
import { GiUmbrella } from "react-icons/gi";
import "./Step3.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectLoginForm,
  updateUserPhoneNum,
  updatePinNum,
  updateProposalName
} from "../../redux/Slice";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import { countries } from "../../helper/CountrtyHelper";
import { Helmet } from "react-helmet-async";


const HealthStep3 = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    number: "",
    district: "",
  });
  const loginPopup = useSelector(selectLoginForm);
  //console.log("loginPopup", loginPopup);
  const [searchByDistrictOrPin, setSearchByDistrictOrPin] = useState("");
  const [pinCode, setPinCode] = useState();
  const [activeLocation, setActiveLocation] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [userPhNumber, setUserPhNumber] = useState(null);
  const [country, setCountry] = React.useState("in");
  const [districtInfo, setDistrictInfo] = useState();

  const [targetdata , setTargetData] = useState();

  const { loginUser } = useSelector((state) => {
    return state.FetchPlan;
  });

  const dispatch = useDispatch();

  //console.log("loginUser", loginUser);

  const getUniqueLocations = useMemo(() => {
    const uniquePincodesSet = new Set();
    const formattedArray = [];

    const DefaultDistrict = localStorage.getItem("District");

    locations.forEach((item) => {
      const district = item.District;
      const pincode = item.Pincode;

      if (uniquePincodesSet.has(district)) {
        const existingEntry = formattedArray.find(
          (entry) => entry.District === district
        );
        existingEntry.Pincode.push(pincode);
      } else {
        uniquePincodesSet.add(district);
        formattedArray.push({
          Pincode: [pincode],
          District: district,
          StateName: item.StateName,
        });
      }
    });

    return formattedArray;
  }, []);

  function isAllDigits(input) {
    // Remove any leading/trailing spaces
    input = input.trim();
    
    // Check if the input is a number and doesn't contain any non-digit characters
    return !isNaN(input) && /^\d+$/.test(input);
  }

  const filteredLocations = useMemo(() => {
    const term = searchByDistrictOrPin.trim().toLowerCase();
    if (!term) {
      return [];
    }

    return getUniqueLocations.filter((item) => {
      return (
        item.District.toLowerCase().includes(term) ||
        item.Pincode.some((pin) => pin.toString().includes(term))
      );
    });
  }, [getUniqueLocations, searchByDistrictOrPin]);

  //console.log("The pincodeFunction", filteredLocations);

  const [DistrictData, setDistrictData] = useState("");
  const [District, setDistrict] = useState("");
  const [Search, setSearch] = useState("");


  const hendelDatachange = (e) => {
    let InputData = e.target.value;

    console.log("e.target.value", e.target.value)
    setTargetData(e.target.value);


    let data = isAllDigits(e.target.value);

    let val = (e.target.value).length <= 7

    if(data && val){
      setSearch(InputData);
  
      if (InputData.length === 7) {
        setDistrictData((prevData)=> prevData = filteredLocations);
        return filteredLocations
      } else {
        setDistrictData("");
        return ""
      }
    }
  
  };

  const [ReconstData, setReconstData] = useState([]);
  const [resultObject, setResultObject] = useState(null); // Initialize resultObject state

  useEffect(() => {
    if (DistrictData) {
      setReconstData(DistrictData);
    }
  }, [DistrictData, Search, setReconstData]);

  // Function to find object by pincode
  const findObjectByPincode = (pincode) => {
    return ReconstData.find((obj) => obj.Pincode.includes(pincode));
  };

  const findObjectByPincodeNew = (pincode, newData) => {
    return newData.find((obj) => obj.Pincode.includes(pincode));
  };

  // Example usage to find object with pincode '400037'
  useEffect(() => {

   
    const intValue = parseInt(Search, 10);
    const foundObject = findObjectByPincode(intValue);
    setResultObject(foundObject); // Update resultObject state

    // If you need to perform actions based on resultObject immediately
    if (foundObject) {
      setDistrict(foundObject.District);
      handleSelectedLocation(foundObject);
    } else {
      setDistrict("");
    }
  }, [Search, ReconstData]); // Watch for changes in Search and ReconstData

  // Use resultObject directly if needed elsewhere in your component
  console.log("Found object:", resultObject);

  // Your component continues...

  //console.log("District", District);

  const handleSearchByDistrictOrPin = (e) => {
    if (!e.target.value) {
      setIsSelected(false);
      setSearchByDistrictOrPin("");
      setPinCode();
    }
    if (e.target.value && Number(e.target.value)) {
      setPinCode(e.target.value);
    }
    setSearchByDistrictOrPin(e.target.value);
  };

  const handleSelectedLocation = (location) => {
    setSearchByDistrictOrPin(location.District);
    setActiveLocation((prevData)=> prevData ={
      District: location.District || filteredLocations.District,
      StateName: location.StateName,
      Pincode: location.Pincode,
    });
    let data = {
      District: location.District || filteredLocations.District,
      StateName: location.StateName,
      Pincode: location.Pincode,
    }
    setIsSelected(true);

    return data
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      number: "",
      district: "",
    };

    let pindata = localStorage.getItem("PinCode");

    // console.log("inputvalue and defaultvalue", inputValue , DefaultInput);
    // console.log("pindata", pindata);

    if (!name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (
      !number ||
      number == "undefined" ||
      number == "" ||
      number.length != 10
    ) {
      newErrors.number = "Phone number is required";
      isValid = false;
    }
    // if(number.trim()!= 10){
    //   newErrors.number = "Please Enter 10 digit Phone Number";
    //   isValid = false;
    // }

    if (number.length != 10) {
      newErrors.number = "Please enter a valid phone number";
      isValid = false;
    }

    if (!activeLocation) {
      newErrors.district = "District is required";
      isValid = false;
    }
    if (pindata && pindata.trim().length != 6) {
      newErrors.district = "Please enter a 6-digit pin code";
      isValid = false;
    }
    if (!pindata) {
      newErrors.district = "Please enter a valid pin code";
      isValid = false;
    }
    // alert(JSON.stringify(activeLocation))

    setErrors(newErrors);
    return isValid;
  };


 const validatePhoneNum = (e)=>{
  let newErrors
  if (
    !e.target.value ||
    e.target.value == "undefined" ||
    e.target.value == "" ||
    e.target.value.length != 10
  ) {
    newErrors = "Phone number is required";
   
  }


  if (e.target.value.length != 10) {
    newErrors = "Please enter a valid phone number";
 
  }

  setErrors(prevErrors => ({
    ...prevErrors,  
    number: newErrors  
  }));
 }


 const validateName = (e)=>{
  let newErrors;
  if (!e.target.value) {
    newErrors = "Name is required";
  
  }
  setErrors(prevErrors => ({
    ...prevErrors,  
    name: newErrors  
  }));
 }

 const validatePinCode = (e)=>{

  let distData = hendelDatachange(e) || [];
  const intValue = parseInt(e.target.value, 10);
  const foundObject = findObjectByPincodeNew(intValue , distData);
  setResultObject(foundObject); // Update resultObject state

  // If you need to perform actions based on resultObject immediately
let activedata 

  if (foundObject) {
    setDistrict(foundObject.District);
    activedata = handleSelectedLocation(foundObject);
  }

// alert(activedata)
  let newErrors 
  let pin = e.target.value;
  if (pin && pin.trim().length != 6 && pin != null) {
    newErrors= "Please enter a 6-digit pin code";
 
  }
  if (!pin && pin != null) {
    newErrors = "Please enter a valid pin code";
  
  }
  if (!activedata && activedata != null) {
    newErrors = "District is required";
    
  }
  // alert(JSON.stringify(activeLocation))

  setErrors(prevErrors => ({
    ...prevErrors,  
    district: newErrors  
  }));

 }


  const handleSubmit = (event) => {
    let pindata = localStorage.getItem("PinCode");
    dispatch(updatePinNum(pindata));

    event.preventDefault();
    //console.log(searchByDistrictOrPin);
    if (validateForm()) {
      localStorage.setItem("ProposalName", name);
      localStorage.setItem(
        "Proposalphonenumber",
        !number ? loginUser?.data?.mobile_number : number
      );
      localStorage.setItem("District", activeLocation?.District);
      localStorage.setItem("State", activeLocation?.StateName);
      // localStorage.setItem(
      //   "PinCode",
      //   pinCode && activeLocation?.Pincode?.includes(Number(pinCode))
      //     ? Number(pinCode)
      //     : activeLocation?.Pincode?.[0]
      // );
      navigate("/healthStep4");
    } else {
      // Handle form validation errors or prevent further action
    }
  };

  useEffect(() => {
    const storedName = localStorage.getItem("ProposalName");
    const storedNumber = localStorage.getItem("Proposalphonenumber");
    const storedDistrict = localStorage.getItem("District");
    const storedPincode = localStorage.getItem("PinCode");

    if (!storedNumber) {
      if (loginUser?.data?.mobile_number) {
        localStorage.setItem(
          "Proposalphonenumber",
          loginUser?.data?.mobile_number
        );
      }
    }

    if (storedName) {
      setName(storedName);
    }
    if (storedNumber) {
      setNumber(storedNumber);
    }
    if (storedDistrict) {
      setSearchByDistrictOrPin(storedDistrict);
      setIsSelected(false);
    }
  }, []);

  const [isVisible, setIsVisible] = React.useState(false);

  const animations = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0%)" : "translateY(50%)",
    config: { mass: 1, tension: 100, friction: 20, duration: 500 },
  });

  useEffect(() => {
    setIsVisible(true);
    window.scrollTo(0, 0);
  }, []);

  // let inputValue = DistrictData ? `${searchByDistrictOrPin} ${Search}` : Search;
  // alert(inputValue);

  let inputValue;
  if (targetdata?.length <= 7 && isAllDigits(targetdata)) {
    if(activeLocation){
      inputValue = DistrictData ? `${searchByDistrictOrPin} ${Search}` : Search;

    }
    else{
      inputValue = DistrictData ? `${Search}` : Search;
    }

    console.log("input value in if", inputValue)
   
  }
  else if (targetdata?.length > 7) {
    const parts = targetdata.trim().split(/\s+/); // Split by spaces
    const textPart = parts.slice(0, -1).join(" "); // Get the text part (all but the last part)
    const pinPart = parts[parts.length - 1].substring(0, 6); // Get the first 6 digits of the pin
  
    // Combine text and pin number
    inputValue = `${textPart} ${pinPart}`;

    console.log("input value in else", inputValue)


    localStorage.setItem('PinCode', pinPart)

  }


  const [defaultPincodeis, setdefaultPincodeis] = useState("");
  const [defaultStateis, setdefaultStateis] = useState("");

  useEffect(() => {
    defaultPincode();
  }, []);

  const defaultPincode = () => {
    const UserDisrict = localStorage.getItem("District");
    setdefaultStateis(UserDisrict);
    const Userpincode = localStorage.getItem("PinCode");
    setdefaultPincodeis(Userpincode);
  };

  let DefaultInput = `${defaultStateis ? defaultStateis : ""} ${
    defaultPincodeis ? defaultPincodeis : ""
  }`;

  // my work ---------------------------------------------------------------------------

  // check login or not --------------------

  let userTokenData = localStorage.getItem("userToken");

  const checkLogin = () => {
    let userTokenData = localStorage.getItem("userToken");

    if (userTokenData) {
      let userNum = localStorage.getItem("Proposalphonenumber");
      setUserPhNumber(userNum);
    } else {
      return false;
    }
  };

  useEffect(() => {
    checkLogin();
  }, [userPhNumber]);

  useEffect(() => {
    if (userTokenData) {
      let userNum = localStorage.getItem("Proposalphonenumber");
      setUserPhNumber(userNum);
      setNumber(userNum);
    }
  }, [userTokenData]);

  // validation of the district -----------------

  useEffect(() => {
    let dist = localStorage.getItem("District");
    let pindata = localStorage.getItem("PinCode");
    let statedata = localStorage.getItem("State");

    setActiveLocation({
      District: dist,
      StateName: statedata,
      Pincode: pindata,
    });
  }, []);

  useEffect(() => {
    if (DefaultInput) {
      setShowDropdown(false);
    }
  }, []);

  // if not login update new phone number to redux to show on popup ----------------------------

  const handleNumberUpdate = () => {
    let data = localStorage.getItem('ProposalName')
    dispatch(updateUserPhoneNum(userPhNumber));
    dispatch(updateProposalName(data))
  };

  // Example array of countries with their flags

  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
       <Helmet>
        <title>HealthStep3</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
      <h1
        style={{
          textAlign: "center",
          padding: "20px",
          color: "#0055A5",
          marginTop: 20,
        }}
      >
        We Commited to <span style={{ color: "#E50318" }}>Safe </span>
        <span style={{ color: "#FD9202" }}>Your Family</span>
        <sup>**</sup>
      </h1>
      <StepSlider activeStep={3} />
      <animated.div style={animations}>
        <h3 className="helth3header">Fill The Below Information</h3>
        <div
          className="textmassage"
          style={{ margin: "auto", textAlign: "center" }}
        >
          <span
            style={{
              color: "#2959B8",
              fontSize: "0.9rem",
              fontFamily: "inherit",
            }}
          >
            Your Premium Amount Depends on Your Area's ZIP Code **
          </span>
        </div>
        <div className="step3-container">
          {/* Tool tip bar Starty Hear */}

          {/* Tool tip bar End Hear */}
          <div className="container tooltipposition">
            <div className="tooltips">
              <div>
                <div className="toolchild">
                  <span className="umbrellaspan">
                    <GiUmbrella />
                  </span>
                  <h6>Best Discount Only for you in limited Price </h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8" style={{ margin: "0 auto" }}>
                <div className="step3-tile">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group design">
                      <label className="alignment" htmlFor="fname">
                        Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        // id="name"
                        // name="name"
                        value={name}
                        id={`search-${Math.random()}`}
                        name={`random-${Math.random()}`}
                  
                         autoComplete="new-password"
                        onChange={(e) => {setName(e.target.value);
                          validateName(e)
                        }}
                      />
                      <span className="error-message">{errors.name}</span>
                    </div>
                    <div className="form-group design phoneflex">
                      {/* <input
                      type="text"
                      value="+1"
                      readOnly
                      style={{ width: "50px", marginRight: "5px" }}
                    /> */}
                      <FormControl
                        sx={{
                          width: "13%",
                          position: "absolute",
                          minWidth: "100px",
                        }}
                      >
                        <Select
                          labelId="country-label"
                          // id="country-select"
                          value={country}
                          onChange={handleChange}

                          id={`search-${Math.random()}`}
                          name={`random-${Math.random()}`}
                    
                           autoComplete="new-password"
                          label="Country"
                          sx={{ width: "100%" }}
                          renderValue={(selected) => (
                            <Box display="flex" alignItems="center">
                              <Box mr={1}>
                                {
                                  countries.find(
                                    (item) => item.code === selected
                                  )?.flag
                                }
                              </Box>
                              {
                                countries.find((item) => item.code === selected)
                                  ?.label
                              }
                            </Box>
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200, // Adjust the height as needed
                              },
                            },
                          }}
                        >
                          {countries.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              <Box display="flex" alignItems="center">
                                <Box mr={1}>{country.flag}</Box>
                                {country.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <div className="phonenum-digits">
                        <label
                          className="alignment phoneStyle"
                          htmlFor="phoneInput"
                        >
                          Phone Number:
                        </label>
                        {/* <PhoneInput
                      country={"in"}
                      placeholder="Enter Phone Number"
                      value={
                        !loginUser?.data?.mobile_number
                          ? number
                          : "+91" + loginUser?.data?.mobile_number
                      }
                      onChange={(value) => {
                        setNumber(value);
                      }}
                      inputStyle={{ width: "100%" }}
                    /> */}
                        {/* <label htmlFor="phoneInput">Phone Number:</label> */}
                        <input
                          // id="phoneInput"
                          id={`search-${Math.random()}`}
                          name={`random-${Math.random()}`}
                    
                           autoComplete="new-password"
                          className="form-control phone-inp"
                          type="number"
                          maxLength={10}
                          defaultValue={
                            (userPhNumber || number ) && (userPhNumber || number ) != "undefined"
                              ? userPhNumber || number
                              : null
                          }
                          onChange={(e) => {

                              // if(e.target.value.length <= 10){
                                setUserPhNumber(e.target.value);
                                setNumber(e.target.value);
                                validatePhoneNum(e)
                              // }
                           
                            

                          }}
                          // disabled={
                          //   localStorage.getItem("userToken") && number != "undefined" && number != "" && number.length == 10? true : false
                          // }
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            paddingLeft: "107px",
                            paddingTop: "13px",
                          }}
                        ></input>
                      </div>

                      <span className="error-message">{errors.number}</span>
                    </div>
                    {/* <div className="form-group design">
                    <label className="alignment" htmlFor="address">
                      Search for district and pincode:
                    </label>
                    <input
                      list="addressOptions"
                      className="form-control"
                      id="searchCoin"
                      autoFocus="autofocus"
                      onChange={(e) => {
                        handleSearchByDistrictOrPin(e);
                        hendelDatachange(e);
                      }}
                      value={inputValue || DefaultInput}
                      onKeyDown={(e) => {
                        if (e.keyCode === 8) {
                          setSearchByDistrictOrPin("");
                          setSearch("");
                          setdefaultPincodeis("");
                          setdefaultStateis("");
                        }
                      }}
                    />
                    <span className="error-message">{errors.district}</span>
                    {filteredLocations.length > 0 ? (
                      <ul className={isSelected ? "d-none" : "custom-dropdown"}>
                        {filteredLocations.map((item) => {
                          return (
                            <li
                              key={item.District}
                              onClick={() => handleSelectedLocation(item)}
                            >
                              {item.District}
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </div> */}
                    <div className="form-group design">
                      <label className="alignment" htmlFor="address">
                        Search by pincode:
                      </label>
                      <input
                        list="addressOptions"
                        className="form-control"
                        // id="searchCoin"

                        id={`search-${Math.random()}`}
                        name={`random-${Math.random()}`}
                  
                         autoComplete="new-password"
                        autoFocus="autofocus"
                        onChange={(e) => {
                          hendelDatachange(e);
                          if(e.target.value.length <= 7 || e.target.value == null || e.target.value == undefined){
                            validatePinCode(e)

                          }
                          handleSearchByDistrictOrPin(e);
                         

                          if (e.target.value == "") {
                            setActiveLocation("");
                          }
                          localStorage.setItem("PinCode", e.target.value);
                        }}
                       
                        value={inputValue || DefaultInput}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            setSearchByDistrictOrPin("");
                            setSearch("");
                            setdefaultPincodeis("");
                            setdefaultStateis("");
                            setTargetData('')
                            
                          }
                        }}
                      />
                    
                      <span className="error-message">{errors.district}</span>
                      {showDropdown && (
                        <ul
                          className={isSelected ? "d-none" : "custom-dropdown"}
                        >
                          {filteredLocations.map((item) => (
                            <li
                              key={item.District}
                              onClick={() => handleSelectedLocation(item)}
                            >
                              {item.District}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className="buttons-container">
                      <button
                        className="continuebutton myBtn"
                        onClick={() => {
                          handleNumberUpdate();
                          // alert(JSON.stringify(activeLocation))
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default HealthStep3;
