import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import '../SIP/SIP.css'
import Slider from '@mui/material/Slider';
import { Link } from 'react-router-dom';
import Redirect from '../../../AllPageComponents/Rederict';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import { Helmet } from 'react-helmet-async';

const SIP = () => {
var convertRupeesIntoWords = require('convert-rupees-into-words');


// Monthly Investemnt
const [monthlyInvestment, setMonthlyInvestment] = useState(25000);

const handleSliderChange = (event, newValue) => {
  setMonthlyInvestment(newValue);
};

const handleInputChange = (event) => {
  const value = event.target.value;

  if (value === '') {
    setMonthlyInvestment('');
  } else if (!isNaN(value)) {
    const numericValue = Number(value);

    // Ensure the value does not exceed 1,000,000
    if (numericValue > 1000000) {
      setMonthlyInvestment(1000000);
    } else {
      setMonthlyInvestment(numericValue);
    }
  }
};

const handleBlur = () => {
  // Ensure the value is within the range [500, 1,000,000]
  if (monthlyInvestment === '') {
    setMonthlyInvestment(500);
  } else if (monthlyInvestment < 500) {
    setMonthlyInvestment(500);
  } else if (monthlyInvestment > 1000000) {
    setMonthlyInvestment(1000000);
  }
};







//Avarage return per anum
const [yearlyReturn, setYearlyReturn] = useState(12);

const handleYearlyReturnChange = (event) => {
  const value = event.target.value;

  // Allow the input to be a string temporarily (to handle cases like "5." or "5.8")
  if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
    if (parseFloat(value) > 50) {
      setYearlyReturn(50);
    } else {
      setYearlyReturn(value);
    }
  }
};

const handleSliderValueChange = (event, newValue) => {
  setYearlyReturn(newValue <= 50 ? newValue : 50);
};

const validateInputOnBlur = () => {
  if (yearlyReturn === '' || isNaN(yearlyReturn)) {
    setYearlyReturn(0); // Set a default value if left empty or invalid
  } else {
    setYearlyReturn(parseFloat(yearlyReturn)); // Convert to number after blur
  }
};








// Time period of sip
const [sipTimePeriod, setSipTimePeriod] = useState(10);

const handleTimePeriodChange = (event) => {
  let value = event.target.value;

  // Ensure the value is a whole number and within the allowed range
  if (value === '') {
    setSipTimePeriod(''); // Allow the input to be empty temporarily
  } else if (!isNaN(value) && /^[0-9]+$/.test(value)) {
    value = parseInt(value, 10);

    if (value > 40) {
      setSipTimePeriod(40); // Limit the value to 40 if it exceeds
    } else {
      setSipTimePeriod(value);
    }
  }
};

const handleTimePeriodSliderChange = (event, newValue) => {
  setSipTimePeriod(newValue <= 40 ? newValue : 40);
};

const validateTimePeriodOnBlur = () => {
  if (sipTimePeriod === '' || isNaN(sipTimePeriod)) {
    setSipTimePeriod(1); // Set a default value if left empty or invalid
  }
};



// Initialize these variables outside the functions
let totalAmountWithoutInterest;
let interestAmount;

const calculateTotalAmount = () => {
  // Monthly investment amount
  const monthlyInvestmentAmount = monthlyInvestment;

  // Monthly interest rate (yearly return converted to monthly)
  const monthlyInterestRate = yearlyReturn / 100 / 12;

  // Number of compoundings per year
  const compoundingsPerYear = 12;

  // Total number of months (investment period * compoundings per year)
  const totalMonths = sipTimePeriod * compoundingsPerYear;

  // Total amount invested without interest (P * n)
  totalAmountWithoutInterest = monthlyInvestmentAmount * totalMonths;
  //console.log("Value is now ",totalAmountWithoutInterest)

  // Future value (FV) considering compound interest
  const totalAmount = monthlyInvestmentAmount * (Math.pow(1 + monthlyInterestRate, totalMonths) - 1) / monthlyInterestRate * (1 + monthlyInterestRate);

  // Interest earned (FV - P * n)
  interestAmount = totalAmount - totalAmountWithoutInterest;

  function formatInRupee(amount) {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(amount);
  }
//console.log("formatted in rupee", formatInRupee(totalAmountWithoutInterest))
  return {
    totalAmount: formatInRupee(totalAmount),
    interestAmount: formatInRupee(interestAmount),
    totalAmountWithoutInterest: formatInRupee(totalAmountWithoutInterest),
  };

  
};








// graph start here

// graph start here
const [TotalInvestedAmount, setTotalInvestedAmount] = useState([])
const [TotalReturnAmount, setTotalReturnAmount] = useState([])
const [TotalValue, setTotalValue] = useState([])

const investmentAmount = ()=>{
  const totalInvestmentAmount = calculateTotalAmount().totalAmountWithoutInterest;
  const InvestAmou = parseFloat(totalInvestmentAmount.replace(/[^\d.]/g, ''));
  return InvestAmou;
}

const ReturnAmount = () =>{
  const totalInvestmentreturn = calculateTotalAmount().interestAmount;
  const InvestRetur = parseFloat(totalInvestmentreturn.replace(/[^\d.]/g, ''));
  return InvestRetur;
}

const TotalValuese = () =>{
  const totalInvestmentValue = calculateTotalAmount().totalAmount;
  const InvestValue = parseFloat(totalInvestmentValue.replace(/[^\d.]/g, ''));
  return InvestValue;
}


useEffect(() => {
  const totalInvestmentAmount = calculateTotalAmount().totalAmountWithoutInterest;
  const totalInvestmentreturn = calculateTotalAmount().interestAmount;
  const totalInvestmentValue = calculateTotalAmount().totalAmount;
  // Removing non-numeric characters and parsing the string to a number
  const InvestAmou = parseFloat(totalInvestmentAmount.replace(/[^\d.]/g, ''));
  const InvestRetur = parseFloat(totalInvestmentreturn.replace(/[^\d.]/g, ''));
  const InvestValue = parseFloat(totalInvestmentValue.replace(/[^\d.]/g, ''));
  setTotalInvestedAmount(InvestAmou);
  setTotalReturnAmount(InvestRetur)
  setTotalValue(InvestValue)
  window.scrollTo(0, 0);
}, []); 





// Number to word 


const Investment = convertRupeesIntoWords(investmentAmount())

const totalvalues = convertRupeesIntoWords(Math.ceil(TotalValuese()))

const totalinterestamount = convertRupeesIntoWords(Math.ceil(ReturnAmount()))





  return (
    
  <div>
               <Helmet>
        <title>Online sip calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="sip calculator systematic investment plan calculator online , sip online calculator, sip percentage calculator" />
      </Helmet>




              <section className="margincalculator"></section>

  <div className="headingcalculator">
    <div className="headerminwidth">

  <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>Sip Calculator</span></p>
<h1 className="titlecalculator">Sip <span className='Mainheading'>Calculator</span></h1>
<sapn className="CalculatorDiscription">A SIP calculator estimates future investment value based on factors like amount, duration, and 
expected return rate. It aids investors in planning their financial goals</sapn>
    </div>
</div>

  <div className="calculatorhead">
  

  <div className="underdiv">
  <div className="calculatorse">

  {/* Calculator start div start hear */}
  <div className="calculaterse">


<div className="outerdiv_chnage">
  <div className="calculator">






{/* Amount calculator */}
<div className="onecalculator">
      <div className="amountdives">
        <p>Monthly Investment</p>
        <input
          type="text"
          value={monthlyInvestment}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="amountselectordiv">
        <Slider
          value={typeof monthlyInvestment === 'number' ? monthlyInvestment : 500}
          onChange={handleSliderChange}
          min={500}
          max={1000000} // Set the max to 1,000,000
          step={500} // Optional: set the step value
          aria-label="Monthly Investment"
          valueLabelDisplay="auto"
        />
      </div>
    </div>

{/* Amount calculator */}
<div className="onecalculator">
      <div className="amountdives">
        <p>Expected return Year % (P.A)</p>
        <input
          type="text"
          value={yearlyReturn}
          onChange={handleYearlyReturnChange}
          onBlur={validateInputOnBlur}
        />
      </div>
      <div className="amountselectordiv">
        <Slider
          value={typeof yearlyReturn === 'number' ? yearlyReturn : 0}
          onChange={handleSliderValueChange}
          min={1}
          max={50} // Set the max to 50
          step={0.1}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      </div>
    </div>

{/* Amount calculator */}
<div className="onecalculator">
      <div className="amountdives">
        <p>Time Period (Year)</p>
        <input
          type="text"
          value={sipTimePeriod}
          onChange={handleTimePeriodChange}
          onBlur={validateTimePeriodOnBlur}
        />
      </div>
      <div className="amountselectordiv">
        <Slider
          value={sipTimePeriod}
          onChange={handleTimePeriodSliderChange}
          min={1}
          max={40} // Set the max to 40 years
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      </div>
    </div>





  </div>
  <div className="graphdiv">
 {//console.log("the values in chart",TotalInvestedAmount, TotalReturnAmount, TotalValue )
 }
   <Chart
        type='donut'
        width={400}
        height={445}
        series={[
          (investmentAmount() !== undefined && investmentAmount() !== null) ? Number(investmentAmount()) : 100,
          (ReturnAmount() !== undefined && ReturnAmount() !== null) ? Number(ReturnAmount()) : 100
        ]}
        
        
        options={{
          labels:['Invested Amount', 'Return Value']
        }}
      />

 





  
  </div>
  </div>


  <div className="calculateamountdiv">

<div className="coverdiv">
<p>Investment Value: <span style={{color:'#0166B3'}}>{String(calculateTotalAmount().totalAmountWithoutInterest).slice(0, -3)}</span></p> 
<p className='textword'>{Investment}</p>
</div>

<div className="coverdiv">
<p >Interest Amount: <span style={{color:'#0166B3'}}>{String(calculateTotalAmount().interestAmount).slice(0, -3)}</span></p>
<p className='textword'>{totalinterestamount}</p>
</div>

<div className="coverdiv">
<p>Total Value: <span style={{color:'#0166B3'}}>{String(calculateTotalAmount().totalAmount).slice(0, -3)}</span></p>
<p className='textword'>{totalvalues}</p> 
</div>

</div>
  </div>
  </div>
  {/* Result part start hear */}
  <div className="resultse">
 <Calculatorlist/>
  </div>
  
  
  </div>
  </div>
  </div>
  );
 
};

export default SIP;
