import React,{useEffect, useState} from 'react'
import '../HuamnLifeCalculator/HLV.css'
import Slider from "@mui/material/Slider";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { IoChevronBackOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import FastImage from '../../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { Helmet } from 'react-helmet-async';




const HLV = () => {

const [userAge, setUserAge] = useState(18);
const [RetairAge, setRetairAge] = useState(20)
const [AnnulIncomese, setAnnulIncome] = useState(100000)
const [MonthlyExpense, setMonthlyExpense] = useState(1000)
const [ExsistingCover, setExsistingCover] = useState(0)
const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);


const handleCheckboxChange = (event) => {
  setIsCheckboxChecked(event.target.checked);
};

var convertRupeesIntoWords = require('convert-rupees-into-words');

const handleAgeChange = (e) => {

  let value = e.target.value;

  if (value === '') {
    setUserAge(''); // Set to an empty string if the input is cleared
  } else {
    value = Number(value);
    if (value > 100) {
      value = 100; // Set the value to 60 if it's greater than 60
    }
    setUserAge(value);
  }
};



const Retairmentage = (e) =>{


  let value = e.target.value;

  if (value === '') {
    setRetairAge(''); // Set to an empty string if the input is cleared
  } else {
    value = Number(value);
    if (value > 60) {
      value = 60; // Set the value to 60 if it's greater than 60
    }
    setRetairAge(value);
  }


}







const AnnualIncome = (event) =>{
    setAnnulIncome(event.target.value)
}
const MonthlyExpensese = (event) =>{
    setMonthlyExpense(event.target.value)
}
const ExsistingCovers = (event) =>{
    setExsistingCover(event.target.value)
}




const calculateAge =  RetairAge - userAge 
const UserAnnualIncome = AnnulIncomese 
const growthRate = 0.15;
const finalAnnualIncome = UserAnnualIncome * Math.pow(1 + growthRate, calculateAge);
// //console.log(`After ${calculateAge} years, your annual income will be ₹${finalAnnualIncome.toFixed(2)}`);


// Influation Value

const Yearly_Expense = MonthlyExpense * 12;
const Inflation_Rate = 0.12;
const InfluationValue = Yearly_Expense * Math.pow(1 + Inflation_Rate, calculateAge);
const RoundInfluation_Value = Math.round(InfluationValue)
// //console.log("The Inflation is", RoundInfluation_Value);


// Fainal Value is 
const fainalValueof = finalAnnualIncome - RoundInfluation_Value
const FainalHLV = Math.round(fainalValueof-ExsistingCover)
const fainalHLV = FainalHLV 
const FainalDataVariable = Math.round(fainalHLV / 2)

//console.log("The Income is",FainalDataVariable)


const [tab, setTab] = useState(1)
useEffect(() => {
  window.scrollTo(0, 0);

  const animationDuration = 1000; 
  setTimeout(() => {

  }, animationDuration);
}, [tab]);


  return (
<div>
<Helmet>
        <title>Human life value calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>




<section className="margincalculator"></section>
<div className="hlv-container">
<div className="headerminwidth">

<p className='breadcum'>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>HLV Calculator</span></p>
<h1 className='headingcolor'>Human Life Value <span className='Mainheading'>Calculator</span></h1>
<p>The Human Life Value (HLV) Calculator helps you identify your life insurance needs on basis of income expenses, liabilities and investments and secure your family’s future.</p>
</div>
</div>





{/* The Calculator Start Hear */}



<div className="underdiv">

<div className="latemaincalculator">

{tab === 1 && (
<div className="calculator-hlv">
<div className="under-hlv">

<p>Current age</p>

<div className="section-lvh">
<div className='slider-lvh'>
<Slider
   value={userAge}    
   onChange={handleAgeChange}
min={1}
max={100}
step={1}
aria-label="Monthly Investment"
valueLabelDisplay="auto"
/>
</div>
<div className='value-lvh'>
<input type="number" value={userAge} onChange={handleAgeChange}/>
</div>
</div>

              <p>Expected Retairment Age</p>
              <div className="section-lvh">
<div className='slider-lvh'>
<Slider
   value={RetairAge}     
onChange={Retairmentage}      
min={1}
max={60}
step={1}
aria-label="Monthly Investment"
valueLabelDisplay="auto"
/>
</div>
<div className='value-lvh'>
<input type="number"  value={RetairAge}  onChange={Retairmentage} />
</div>
</div>
              <p>Annul Income</p>
              <div className="section-lvh">
<div className='slider-lvh'>
<Slider
   value={AnnulIncomese}  
 onChange={AnnualIncome}          
min={500}
max={10000000}
step={1000}
aria-label="Monthly Investment"
valueLabelDisplay="auto"
/>
</div>
<div className='value-lvh'>
<input type="number" value={AnnulIncomese}  onChange={AnnualIncome}  />
</div>
</div>
              <p>Monthly Expense</p>
              <div className="section-lvh">
<div className='slider-lvh'>
<Slider
onChange={MonthlyExpensese}
   value={MonthlyExpense}            
min={500}
max={1000000}
step={1000}
aria-label="Monthly Investment"
valueLabelDisplay="auto"
/>
</div>
<div className='value-lvh'>
<input type="number"    value={MonthlyExpense}   onChange={MonthlyExpensese} />
</div>
</div>

<p>Exsisting Cover</p>
              <div className="section-lvh">
<div className='slider-lvh'>
<Slider
onChange={ExsistingCovers}
   value={ExsistingCover}            
min={500}
max={30000000}
step={10000}
aria-label="Monthly Investment"
valueLabelDisplay="auto"
/>
</div>
<div className='value-lvh'>
<input type="number" value={ExsistingCover} onChange={ExsistingCovers}/>
</div>
</div>
<div className="section-lvh-select">

<Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
Education Qulification
        </InputLabel>
        <NativeSelect
          defaultValue={30}
          inputProps={{
            name: 'age',
            id: 'uncontrolled-native',
          }}
        >
        <option value={30}>10th Pass</option>
          <option value={10}>Bachlors Degree</option>
          <option value={20}>Master Degree</option>
        </NativeSelect>
      </FormControl>
    </Box>
</div>
<div className="calculatemassage">
<input type="checkbox" onChange={handleCheckboxChange} /><span> &nbsp;

I hereby consent to receive policy related / documents / application related communication from Beema1 or its authorized representatives via Call, SMS, Email & Voice over Internet Protocol (VoIP) including WhatsApp and agree to waive my registration on NCPR (National Customer Preference Registry) in this regard.</span>
</div>
<div className="calculatediv">
<button className={isCheckboxChecked ? "calculatebuttonse" : "calculatebuttondesable"} onClick={() => setTab(tab + 1)} disabled={!isCheckboxChecked}>Calculate</button>

</div>
</div>
</div>
)}

{
  tab === 2 && (
    <>
    <div className="backdiv"><button onClick={()=>{setTab(1)}} style={{background:"none",border:"none",fontSize:"25pt"}}><IoChevronBackOutline/></button></div>
    <div className="showData-Container">
    
    <FastImage
            url={"Website%20Img/HLVValue.png?updatedAt=1718859792771"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
    <div className="divprimium">
    <h2>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(FainalDataVariable)}</h2>
  
    <p>{convertRupeesIntoWords(FainalDataVariable)}</p>
  
    <span>is your Plane</span>
    <button>Explore Planes</button>
    
    </div>
    
    
    </div>
    </>
  )
}






</div>

<div className="resultse">

<Calculatorlist/>

</div>

</div>




</div>
  )
}

export default HLV