import React, { useEffect, useRef, useState } from "react";
import "../../CommonCssFiles/planstyle.css";
import relation from "../../../relation.json";
import { useDispatch, useSelector } from "react-redux";
import {
  updateInsuredMembers,
  selectProposal,
  updateInsuredArray,
} from "../../redux/Slice";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { insuredDataSequence } from "../../helper/common";
import "./EditDetails.css";
import { RxCross1 } from "react-icons/rx";
import { Button } from "@mui/material";
import "../../CommonCssFiles/planstyle.css";
import { updateQuickQuote, fetchQuickQuote } from "../../Api/Api";
import Loader from "../../../AllPageComponents/Loader";
import { Select, MenuItem } from "@mui/material";
import { settingReduxData } from "../../Api/Api";

const ages = Array.from({ length: 121 }, (_, i) => i);

const levelToAgeRange = {
  Self: [18, 100],
  Wife: [18, 100],
  Husband: [18, 100],
  Brother: [18, 100],
  Sister: [18, 100],
  Father: [40, 100],
  Mother: [40, 100],
  Uncle: [40, 100],
  Aunt: [40, 100],
  "Father In Law": [40, 100],
  "Mother In Law": [40, 100],
  Son: [1, 30],
  Daughter: [1, 30],
  "Grand Daughter": [1, 30],
  "Grand Son": [1, 30],
  "Grand Father": [54, 100],
  "Grand Mother": [54, 100],
};

const EditDetails = ({
  showSide,
  onBack,
  apiResponse,
  handleUpdatedData,
  setShowside,
  setApiResponse,
  setSidedata,
  setPlanData,
  isConnected
}) => {
  const [sonCount, setSonCount] = useState(0);
  const [daughterCount, setDaughterCount] = useState(0);
  const [prevSonCount, setPrevSonCount] = useState(0);
  const [prevDaughterCount, setPrevDaughterCount] = useState(0);
  const [newlyAddedSonId, setNewlyAddedSonId] = useState(null);
  const [newlyAddedDaughterId, setNewlyAddedDaughterId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponseEdit, setApiResponseEdit] = useState();
  const dispatch = useDispatch();
  // const { userdata } = useSelector((state) => {
  //   return state.FetchPlan;
  // });
  // alert(JSON.stringify(userdata))
  const [membersList, setMembersList] = useState([]);
  const [oldMemberList, setOldMemberList] = useState([]);
  const [checkedElements, setCheckedElements] = useState();
  const [prevInsured, setPrevInsured] = useState([]);

  // -----
  const [isDisabled, setIsDisabled] = useState(false);

  console.log("the member list", isDisabled);

  const filtermemberlist = membersList.filter(
    (value) => value.isChecked === true
  );

  useEffect(() => {
    let foundEmptyInsuredAge = false;
    for (let i = 0; i < filtermemberlist.length; i++) {
      const member = filtermemberlist[i];

      if (member.isChecked === true) {
        if (member.InsuredAge === "") {
          foundEmptyInsuredAge = true;
          break;
        }
      }
    }

    // Update the state based on whether an empty InsuredAge was found
    setIsDisabled(foundEmptyInsuredAge);
  }, [filtermemberlist]);

  let type = localStorage.getItem("selectedType");

  const proposalData = useSelector(selectProposal);

  let userdata = proposalData;

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  // alert(JSON.stringify(proposalData))

  const [isSelectOpen, setIsSelectOpen] = useState({});
  const [relTypeChecked, setRelTypeChecked] = useState({
    Son: sonCount > 0,
    Daughter: daughterCount > 0,
  });

  const selectRef = useRef(null);
  const ages = Array.from({ length: 121 }, (_, i) => i);
  const selfWifeAges = Array.from({ length: 82 }, (_, i) => i + 18);
  const parentAges = Array.from({ length: 60 }, (_, i) => i + 40);
  const sonDaughterAges = Array.from({ length: 30 }, (_, i) =>
    i === 0 ? "<91" : i
  );
  const grandparentAges = Array.from({ length: 46 }, (_, i) => i + 54);

  // -----

  const toggleSelect = (index) => {
    setIsSelectOpen((prevIsSelectOpen) => ({
      ...prevIsSelectOpen,
      [index]: !prevIsSelectOpen[index],
    }));
  };

  const [number, setNumber] = useState(0);

  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  const handleCheckboxChange = (id, level) => {
    const updatedMembers = membersList.map((member) => {
      if (member.id === id) {
        member.isChecked = !member.isChecked;
      }
      return member;
    });

    if (level === "Son") {
      const count = updatedMembers.filter(
        (member) => member.level === "Son" && member.isChecked
      ).length;
      setSonCount(count);
      if (count === 0) {
        const resetMembers = updatedMembers.map((member) => {
          if (member.level === "Son") {
            member.isChecked = false;
          }
          return member;
        });
        setMembersList(resetMembers);
      } else {
        setMembersList(updatedMembers);
      }
    } else if (level === "Daughter") {
      const count = updatedMembers.filter(
        (member) => member.level === "Daughter" && member.isChecked
      ).length;
      setDaughterCount(count);
      if (count === 0) {
        const resetMembers = updatedMembers.map((member) => {
          if (member.level === "Daughter") {
            member.isChecked = false;
          }
          return member;
        });
        setMembersList(resetMembers);
      } else {
        setMembersList(updatedMembers);
      }
    } else {
      setMembersList(updatedMembers);
    }
  };

  const [changesApplied, setChangesApplied] = useState(false);
  const navigate = useNavigate();
  const handleInsuredMember = async () => {
    setIsLoading(true);


    let data = isConnected();
    console.log("the data in isconnected", data)

    if(data){
      let result =
      membersList
        .filter((member) => member.isChecked)
        .map((member) => ({
          InsuredRelation: member.level,
          InsuredAge: member.InsuredAge,
        })) || [];

    let countSon = 0;
    let countDaughter = 0;

    for (let i = 0; i < result.length; i++) {
      if (result[i].InsuredRelation === "Son") {
        countSon++;
      }

      if (result[i].InsuredRelation === "Daughter") {
        countDaughter++;
      }
    }

    if (countSon > 0) {
      localStorage.setItem("countSon", countSon);
    } else {
      localStorage.setItem("countSon", 0);
    }

    if (countDaughter > 0) {
      localStorage.setItem("countDaughter", countDaughter);
    } else {
      localStorage.setItem("countDaughter", 0);
    }

    for (let i = 0; i < result.length; i++) {
      if (result[i].InsuredAge < 0 || "") {
        // alert("Invalid Age");
        // alert(JSON.stringify(result))
        return;
      }
    }

    localStorage.setItem(
      "InsuredDetail",
      JSON.stringify(insuredDataSequence(result))
    );

    let payloadData = {
      ...apiResponse.request,
      InsuredDetail: insuredDataSequence(result),
    };

    console.log("apiResponse", apiResponse);
    console.log("payloadData", payloadData);

  

    await updateQuickQuote(payloadData, unique_id).then((res) => {
      console.log("the response", res);
      setIsLoading(false);
      setShowside(false);
      setApiResponse(res.data);
      setPlanData(res.data.response);
      setSidedata(res.data.request);
    });


    // alert(JSON.stringify(result))
    dispatch(updateInsuredArray(insuredDataSequence(result)));
    setChangesApplied(true);
    onBack();
    }
    else{
      return
    }


  };

  useEffect(() => {
    if (showSide == false) {
      setChangesApplied(false);
    }
  }, [showSide]);


 const settingData = async (premiumJson) => {
    try {
      let phNo = localStorage.getItem("Proposalphonenumber");


      let payload = {
       ...apiResponse.request,
       InsuredDetail : JSON.parse(localStorage.getItem('InsuredDetail'))
      }

      let payloadData = {
        proposal_data: payload,
        phone_no: phNo,
      };
      console.log("payloadData", payloadData);

      let response = await settingReduxData(unique_id, payloadData).then(
        (response) => {
          console.log("the response in set reduxdata", response);
        }
      );
    } catch (error) {
      console.log("the error", error);
    }
  };


  const fetchApi = async () => {
    try {
      const response = await fetchQuickQuote(unique_id);
      setApiResponseEdit(response);
      return response;
    } catch (error) {
      console.log("The error", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi();
      if (response) {
        const newData = response.data.request;

        const tempArr = relation.filter((rel) => rel.type === newData?.Gender);
        const filteredMembers = tempArr.map((item) => ({
          level: item.level,
          isChecked: false,
          InsuredAge: 0,
          id: uuidv4(),
        }));

        if (newData?.InsuredDetail?.length) {
          const allMembers = newData.InsuredDetail.map((member) => ({
            level: member.InsuredRelation,
            isChecked: true,
            InsuredAge: member.InsuredAge || 0,
            id: uuidv4(),
          }));

          const uniqueList = filteredMembers.filter(
            (member) =>
              !allMembers.some(
                (existingMember) => existingMember.level === member.level
              )
          );

          const updatedMembersList = [...allMembers, ...uniqueList];

          const reorderedOldMemberList = relation.flatMap((relation) => {
            if (newData?.Gender === relation.type) {
              const members = updatedMembersList.filter(
                (oldMember) => oldMember.level === relation.level
              );
              return members;
            }
            return [];
          });

          setMembersList(reorderedOldMemberList);
          setOldMemberList(reorderedOldMemberList);

          let a_sons = reorderedOldMemberList.filter(
            (e) => e.level === "Son" && e.isChecked
          );
          let a_daughter = reorderedOldMemberList.filter(
            (e) => e.level === "Daughter" && e.isChecked
          );
          setSonCount(a_sons.length);
          setDaughterCount(a_daughter.length);
        } else {
          setMembersList(filteredMembers);
          setOldMemberList(filteredMembers);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initialCheckedElements = [];
    if (showSide && oldMemberList && oldMemberList.length !== 0) {
      oldMemberList.forEach((item) => {
        if (item.isChecked === true) {
          if (item.level !== "Son" && item.level !== "Daughter") {
            initialCheckedElements.push({
              InsuredAge: item.InsuredAge || "",
              InsuredRelation: item.level,
            });
          } else if (item.level === "Son") {
            initialCheckedElements.push({
              InsuredAge: item.InsuredAge || "",
              InsuredRelation: item.level,
            });
          } else if (item.level === "Daughter") {
            initialCheckedElements.push({
              InsuredAge: item.InsuredAge || "",
              InsuredRelation: item.level,
            });
          }
        }
      });

      setCheckedElements(initialCheckedElements);
    }
  }, [showSide]);

  useEffect(() => {
    if (userdata?.InsuredDetail?.length) {
      const sonCountFromPrevData = userdata.InsuredDetail.filter(
        (member) => member.InsuredRelation === "Son"
      ).length;

      const daughterCountFromPrevData = userdata.InsuredDetail.filter(
        (member) => member.InsuredRelation === "Daughter"
      ).length;

      setPrevSonCount(sonCountFromPrevData);
      setPrevDaughterCount(daughterCountFromPrevData);
    }
  }, [userdata?.InsuredDetail]);

  useEffect(() => {
    if (userdata?.InsuredDetail?.length) {
      const sonCountFromPrevData = userdata.InsuredDetail.filter(
        (member) => member.InsuredRelation === "Son"
      ).length;
      const daughterCountFromPrevData = userdata.InsuredDetail.filter(
        (member) => member.InsuredRelation === "Daughter"
      ).length;

      setSonCount(sonCountFromPrevData);
      setDaughterCount(daughterCountFromPrevData);
    }
  }, [userdata?.InsuredDetail]);

  useEffect(() => {
    if (showSide == false) {
      if (userdata?.InsuredDetail?.length) {
        const sonCountFromPrevData = userdata.InsuredDetail.filter(
          (member) => member.InsuredRelation === "Son"
        ).length;
        const daughterCountFromPrevData = userdata.InsuredDetail.filter(
          (member) => member.InsuredRelation === "Daughter"
        ).length;

        setSonCount(sonCountFromPrevData);
        setDaughterCount(daughterCountFromPrevData);
      }
    }
  }, [showSide]);

  //console.log("Count:", prevSonCount, prevDaughterCount);
  //console.log(membersList);

  const updateClassNameBasedOnCount = (member, sonCount, daughterCount) => {
    if (
      (member.level === "Son" && sonCount === 0) ||
      (member.level === "Daughter" && daughterCount === 0)
    ) {
      return "input-group row groupdata";
    }
    return member.isChecked ? "checked-member" : "input-group row groupdata";
  };

  const updateCheckedElements = (member) => {
    let updatedCheckedElements = [...checkedElements];
    const elementIndex = updatedCheckedElements.findIndex(
      (el) => el.InsuredRelation === member.level
    );

    if (member.isChecked) {
      if (elementIndex === -1) {
        updatedCheckedElements.push({
          InsuredRelation: member.level,
          InsuredAge: "",
        });
      }
    } else {
      updatedCheckedElements = updatedCheckedElements.filter(
        (el) => el.InsuredRelation !== member.level
      );
    }

    setCheckedElements(updatedCheckedElements);
  };

  return (
    <>
      {isLoading && <Loader />}

      {changesApplied && (
        <div class="alert alert-success" role="alert">
          Your data has been updated Sucessfully!
        </div>
      )}
      <h3 className="edit-head">Insured Members</h3>
      <div className="scrollable-container">
        {/* 
        
        relations list map
        self,
        if memberslist has self, self
        if memberslist has spouse, spouse
        son label
        while memberslist has son, son

        daughter label
        while memberslist has daughter, daughter
        
        */}
        {console.log("membersList", membersList)}
        {relation.map((relType) => {
          if (relType.type == type) {
            let membersOfCurrentType = membersList.map((member, index) => {
              if (member.level === relType.level)
                return { index: index, member: member };
              return null;
            });
            membersOfCurrentType = membersOfCurrentType.filter(
              (m) => m != null
            );

            /* all members */
            let memberElements = membersOfCurrentType.map((memberOfType) => {
              if (memberOfType !== null) {
                let member = memberOfType.member;
                let index = memberOfType.index;
                return (
                  <div
                    className={updateClassNameBasedOnCount(
                      member,
                      sonCount,
                      daughterCount
                    )}
                    key={uuidv4()}
                  >
                    <div class="w-100">
                      <div className="input-group-prepend" style={{height:"50px"}}>
                        {/* Checkbox */}
                        {/* {member.level !== "Son" && member.level !== "Daughter" && ( */}
                        <div className="input-group-text inputcheck">
                          <input
                            type="checkbox"
                            className="checkpolicy"
                            aria-label={member.level}
                            defaultChecked={
                              (["Son", "Daughter"].includes(relType.level) &&
                                relTypeChecked[relType.level]) ||
                              member.isChecked
                            }
                            onChange={() => {
                              if (["Son", "Daughter"].includes(relType.level)) {
                                if (
                                  (relType.level === "Son" && sonCount === 0) ||
                                  (relType.level === "Daughter" &&
                                    daughterCount === 0)
                                ) {
                                  let removeCount = 0;
                                  if (relType.level === "Son")
                                    removeCount = sonCount === 0 ? 1 : 0;
                                  else if (relType.level === "Daughter")
                                    removeCount = daughterCount === 0 ? 1 : 0;
                                  membersList.splice(index, removeCount, {
                                    level: relType.level,
                                    isChecked: true,
                                    InsuredAge: "",
                                    id: uuidv4(),
                                  });
                                  checkedElements?.push({
                                    InsuredRelation: member.level,
                                    InsuredAge: "",
                                  });
                                  if (relType.level === "Son")
                                    setSonCount(sonCount + 1);
                                  else setDaughterCount(daughterCount + 1);
                                  relTypeChecked[relType.level] = true;
                                } else {
                                  membersList.splice(index, 1);
                                  if (relType.level === "Son")
                                    setSonCount(
                                      sonCount <= 0 ? 0 : sonCount - 1
                                    );
                                  else
                                    setDaughterCount(
                                      daughterCount <= 0 ? 0 : daughterCount - 1
                                    );
                                  relTypeChecked[relType.level] = false;
                                }
                                setMembersList([...membersList]);
                                setRelTypeChecked(relTypeChecked);
                              } else {
                                handleCheckboxChange(member.id, member.level);
                                if (member.isChecked === true) {
                                  checkedElements.push({
                                    InsuredRelation: member.level,
                                    InsuredAge: "",
                                  });
                                }
                                updateCheckedElements(member);
                              }
                            }}
                          />
                        </div>
                        {/* )} */}

                        {/* Relation label */}
                        <span className="membersselect w-100 d-flex align-items-center">
                          {member.level == "Wife"? "Spouse" : member.level}

                          {["Son", "Daughter"].includes(relType.level) &&
                            index === membersOfCurrentType[0].index &&
                            ((relType.level === "Daughter" &&
                              daughterCount !== 0) ||
                              (relType.level === "Son" && sonCount !== 0)) && (
                              <div class="input-group-text inputcheck">
                                <button
                                  className="details-plus-btn"
                                  onClick={() => {
                                    let newIndex = 0;
                                    membersList.find(
                                      (e) => e.level === "Self"
                                    ) && newIndex++;
                                    membersList.find((e) =>
                                      ["Husband", "Wife"].includes(e.level)
                                    ) && newIndex++;
                                    if (relType.level === "Son") {
                                      newIndex += sonCount;
                                    } else if (relType.level === "Daughter") {
                                      newIndex += sonCount + daughterCount;
                                    }
                                    membersList.splice(newIndex, 0, {
                                      level: relType.level,
                                      isChecked: true,
                                      InsuredAge: "",
                                      id: uuidv4(),
                                    });
                                    if (relType.level === "Son")
                                      setSonCount(sonCount + 1);
                                    else setDaughterCount(daughterCount + 1);
                                    setMembersList([...membersList]);
                                    // if(!relTypeChecked[relType.level]){
                                    //   relTypeChecked[relType.level]=true;
                                    //   setRelTypeChecked(relTypeChecked);
                                    // }
                                  }}
                                  disabled={daughterCount + sonCount >= 4}
                                >
                                  {" "}
                                  +{" "}
                                </button>
                              </div>
                            )}
                        </span>

                        {/* age change */}
                        {member.isChecked && (
                          <>
                            {/* <div className="">
                              <input className="" onChange={()=>{}}></input>
                            </div> */}
                            <div
                              className="d-flex align-items-center pr-2"
                              style={{ minWidth: "fit-content" }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                                ref={selectRef}

                              >
                                <Select
                                  className="selectvalues"
                                  id={`age.${index}`}
                                  name={`age.${index}`}
                                  value={member.InsuredAge}
                                  onChange={(event) => {
                                    /* change age here by index of member */
                                    membersList[index].InsuredAge =
                                      event.target.value;
                                    setMembersList([...membersList]);
                                  }}
                                  onClick={() => toggleSelect(index)}
                                  onBlur={() =>
                                    setIsSelectOpen({
                                      ...isSelectOpen,
                                      [index]: false,
                                    })
                                  }
                                  displayEmpty
                                  renderValue={(selected) =>
                                    selected ? `${selected} Years` : "Age"
                                  }
                                >
                                  <MenuItem value="">
                                    <em>Age</em>
                                  </MenuItem>
                                  {[
                                    "Self",
                                    "Wife",
                                    "Husband",
                                    "Brother",
                                    "Sister",
                                  ].includes(member.level)
                                    ? selfWifeAges.map((age) => {
                                      return(
                                        <MenuItem key={age} value={age}>
                                        {age} Years
                                      </MenuItem>
                                      )
                                      
              })
                                    : [
                                        "Father",
                                        "Mother",
                                        "Uncle",
                                        "Aunt",
                                        "Father In Law",
                                        "Mother In Law",
                                      ].includes(member.level)
                                    ? parentAges.map((age) => (
                                        <MenuItem key={age} value={age}>
                                          {age} Years
                                        </MenuItem>
                                      ))
                                    : [
                                        "Son",
                                        "Daughter",
                                        "Grand Daughter",
                                        "Grand Son",
                                      ].includes(member.level)
                                    ? sonDaughterAges.map((age) => (
                                        <MenuItem
                                          key={age}
                                          value={age === "<91" ? 0 : age}
                                        >
                                          {age}{" "}
                                          {age === "<91" ? "Days" : "Years"}
                                        </MenuItem>
                                      ))
                                    : ["Grand Father", "Grand Mother"].includes(
                                        member.level
                                      )
                                    ? grandparentAges.map((age) => (
                                        <MenuItem key={age} value={age}>
                                          {age} Years
                                        </MenuItem>
                                      ))
                                    : ages.map((age) => (
                                        <MenuItem key={age} value={age}>
                                          {age} Years
                                        </MenuItem>
                                      ))}
                                </Select>
                                {/* {isSelectOpen[index] ? (
                                  <i
                                    className="fa fa-caret-down font20"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-caret-up font20"
                                    aria-hidden="true"
                                  ></i>
                                )} */}
                              </div>
                            </div>
                          </>
                        )}

                        {/* Decrease button */}
                        {/* 
                        {(member.level === "Son" || member.level === "Daughter") && (
                          <div className="input-group-text inputcheck">
                            <button
                              className="details-minus-btn"
                              onClick={() => {
                                membersList.splice(index,1);
                                if(relType.level === "Son") setSonCount(sonCount <= 0 ? sonCount : sonCount - 1);
                                else setDaughterCount(daughterCount <= 0 ? daughterCount : daughterCount - 1);
                                setMembersList([...membersList]);
                              }}
                            > - </button>
                          </div>
                        )} 
                        */}
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            });

            //headers for daughter and son only
            if (
              ["Son", "Daughter"].includes(relType.level) &&
              membersOfCurrentType.length === /*  */ 0
            ) {
              let memberLabel = (
                <div className="input-group row groupdata" key={uuidv4()}>
                  <div className="w-100">
                    <div className="input-group-prepend" style={{height:"50px"}}>
                      <div className="input-group-text inputcheck">
                        <input
                          type="checkbox"
                          className="checkpolicy"
                          aria-label={relType.level}
                          defaultChecked={relTypeChecked[relType.level]}
                          onChange={() => {
                            // DO NOT REMOVE BUT UNCHECK ALL INDIVIDUALS
                            // membersOfCurrentType.forEach((memberOfCurrentType)=>{
                            //   handleCheckboxChange(memberOfCurrentType.index, memberOfCurrentType.level);
                            //   updateCheckedElements(memberOfCurrentType);
                            // })

                            // OR JUST REMOVE ALL INDIVIDUALS
                            relTypeChecked[relType.level] =
                              !relTypeChecked[relType.level];
                            if (!relTypeChecked[relType.level]) {
                              if (relType.level === "Son") setSonCount(0);
                              else setDaughterCount(0);
                              if (membersOfCurrentType.length > 0) {
                                membersList.splice(
                                  membersOfCurrentType[0].index,
                                  membersOfCurrentType.length
                                );
                                setMembersList([...membersList]);
                              }
                            } else {
                              let newIndex = 0;
                              membersList.find((e) => e.level === "Self") &&
                                newIndex++;
                              membersList.find((e) =>
                                ["Husband", "Wife"].includes(e?.level)
                              ) && newIndex++;
                              if (relType.level === "Son") {
                                newIndex += sonCount;
                              } else if (relType.level === "Daughter") {
                                newIndex += sonCount + daughterCount;
                              }
                              membersList.splice(newIndex, 0, {
                                level: relType.level,
                                isChecked: true,
                                InsuredAge: "",
                                id: uuidv4(),
                              });
                              if (relType.level === "Son")
                                setSonCount(sonCount + 1);
                              else setDaughterCount(daughterCount + 1);
                              setMembersList([...membersList]);
                              if (!relTypeChecked[relType.level]) {
                                relTypeChecked[relType.level] = true;
                                setRelTypeChecked(relTypeChecked);
                              }
                            }
                            setRelTypeChecked(relTypeChecked);
                          }}
                          disabled={
                            (relType.level === "Son" && daughterCount === 4) ||
                            (relType.level === "Daughter" && sonCount === 4)
                          }
                        />
                      </div>
                      <span className="membersselect w-100 d-flex align-items-center">
                        {relType.level}s
                      </span>

                      {((relType.level === "Daughter" && daughterCount !== 0) ||
                        (relType.level === "Son" && sonCount !== 0)) && (
                        <div class="input-group-text inputcheck">
                          <button
                            className="details-plus-btn"
                            onClick={() => {
                              let newIndex = 0;
                              membersList.find((e) => e.level === "Self") &&
                                newIndex++;
                              membersList.find((e) =>
                                ["Husband", "Wife"].includes(e.level)
                              ) && newIndex++;
                              if (relType.level === "Son") {
                                newIndex += sonCount;
                              } else if (relType.level === "Daughter") {
                                newIndex += sonCount + daughterCount;
                              }
                              membersList.splice(newIndex, 0, {
                                level: relType.level,
                                isChecked: true,
                                InsuredAge: "",
                                id: uuidv4(),
                              });
                              if (relType.level === "Son")
                                setSonCount(sonCount + 1);
                              else setDaughterCount(daughterCount + 1);
                              setMembersList([...membersList]);
                              if (!relTypeChecked[relType.level]) {
                                relTypeChecked[relType.level] = true;
                                setRelTypeChecked(relTypeChecked);
                              }
                            }}
                            disabled={
                              daughterCount + sonCount >= 4 ? "true" : "false"
                            }
                          >
                            {" "}
                            +{" "}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
              return [memberLabel, ...memberElements.filter((m) => m != null)];
            } else {
              return [...memberElements.filter((m) => m != null)];
            }
          }
          return <></>;
        })}

        {/* <div>
          <h3>Insured Member Ages</h3>
          <div className="member-ages-scrolldiv py-3">
            {checkedElements &&
              checkedElements.map((item, index) => {
                return (
                  <div className="edit-age">
                    {item.InsuredRelation}
                    <input
                      className="edit-age-inp"
                      type="number"
                      value={item.InsuredAge ? item.InsuredAge : number}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div> */}
      </div>

      <div className="age-scrollbar-container"></div>

      <div className="searchbuttondiv">
        <button className="sidbtnn backedit" onClick={onBack}>
          Back
        </button>

        <button
          className="sidbtnn applyedit"
          onClick={() => {
            handleInsuredMember();
            settingData()
            // handleUpdatedData();
          }}
          disabled={isDisabled === true}
        >
          Update
        </button>
      </div>
    </>
  );
};

export default EditDetails;
