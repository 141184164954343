// src/ProposalPop.js
import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "./ProposalPop.css";
import { useState } from "react";
import { selectProposal } from "../redux/Slice";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { PiThumbsUpDuotone } from "react-icons/pi";
import { PiThumbsDown } from "react-icons/pi";

const ProposalPop = ({
  show,
  onClose,
  apiResponse,
  proposerData,
  bmidata,
  bmiData,
  setBmiData,
}) => {
  const calculateBMI = (height, weight) => {
    if (height <= 0 || weight <= 0) {
      alert("Height and weight must be greater than zero");
      return;
    }

    let bmidata;
    let categorydata;

    const heightInMeters = height / 100; // Convert height to meters
    const bmiValue = weight / (heightInMeters * heightInMeters);
    // setBmi(bmiValue.toFixed(2));
    bmidata = bmiValue.toFixed(2);

    // Determine BMI category----
    if (bmiValue < 18.5) {
      // setCategory('Underweight');
      categorydata = "Underweight";
    } else if (bmiValue >= 18.5 && bmiValue < 24.9) {
      // setCategory('Normal weight');
      categorydata = "Normalweight";
    } else if (bmiValue >= 25 && bmiValue < 29.9) {
      // setCategory('Overweight');
      categorydata = "Overweight";
    } else {
      // setCategory('Obesity');
      categorydata = "Obesity";
    }

    console.log("bmivalue and category", bmidata, categorydata);

    return [bmidata, categorydata];
  };

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  useEffect(() => {
    if (proposerData.InsuredDetail) {
      const data = proposerData.InsuredDetail.map((item) => {
        const [bmi, category] = calculateBMI(item.heightcm, item.weight);
        return {
          name: item.InsuredName,
          bmi,
          category,
        };
      });

      setBmiData(data);
    }
  }, [proposerData]);

  return (
    <>
      <div className="total-bmi-div">
        <CSSTransition
          in={show}
          timeout={4000}
          classNames="proposal-popup"
          unmountOnExit
          onExited={onClose}
        >
          <div className="proposal-popup">
            {show && (
              <div className="loader-bmi-data">
                <div className="bmi-loader-container">
                  <div className="bmi-loader">
                    Loading Your Details
                    <span className="bmi-loader-dots"></span>
                  </div>
                </div>
              </div>
            )}
            {bmiData.map((item, index) => (
              <div className="proposalpop-body col-md-12" key={index}>
                <div className="bmiresultsprop col-md-12">
                  <div className="bminameshowingdiv col-md-6">
                    <span
                      className={
                        item.category === "Overweight" ||
                        item.category === "Underweight"
                          ? "thumb-iconover"
                          : "thumb-iconnormal"
                      }
                    >
                      {item.category === "Overweight" ||
                      item.category === "Underweight" ? (
                        <PiThumbsDown style={{ marginTop: "5px" }} />
                      ) : (
                        <PiThumbsUpDuotone style={{ marginTop: "5px" }} />
                      )}
                    </span>
                    &nbsp;
                    <span style={{ color: "#0055a5", fontWeight: "500" }}>
                      {item.name}'s
                    </span>
                  </div>

                  <div className="bmishowingdiv col-md-6">
                    <span>
                      <span style={{ color: "#fd9202" }}> BMI:-&nbsp;</span>
                      <span
                        className={
                          item.category === "Overweight" ||
                          item.category === "Underweight"
                            ? "overweight"
                            : "normalbmi"
                        }
                      >
                        {item.bmi} ({item.category})
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CSSTransition>
      </div>


    </>
  );
};

export default ProposalPop;
