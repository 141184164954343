import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../CommonCssFiles/planstyle.css";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { changeDateFormat, getDate } from "../helper/common";
import "./HDFC.css";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, FormLabel } from "@mui/material";
import {
  selectProposal,
  updateHDFCQuestion,
  updateHDFCPersion,
  updateHDFCQAlist,
  selectHDFCcheckedQuestion,
  selectHDFCcheckedPersion,
} from "../redux/Slice";

const search = window.location.search;
const params = new URLSearchParams(search);
const unique_id = params.get("req_id");
const plan_id = params.get("plan_id");
const c_id = params.get("c_id");

export const HDFCQuestionAnswerIndividual = ({
  data,
  index,
  insuredData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  medErrors,
  setMedErrors,
}) => {
  const updateHDFCQuestion = ({ val }) => {
    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      if (updatedState.hasOwnProperty(val)) {
        delete updatedState[val];
      } else {
        updatedState[val] = {};
      }

      return updatedState;
    });
  };

  useEffect(() => {
    if (medErrors.length > 0) {
      const firstErrorElement = document.querySelector(
        `[data-question-id="${medErrors[0]}"]`
      );
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [medErrors]);

  return (
    <>
      <div
        className="ppv10 col-md-12"
        style={{ paddingLeft: "0px", minWidth: "250px" }}
        data-question-id={data.question_id}
      >
        <div
          className="col-md-12 col-sm-12 col-12 pmd d-flex align-items-center"
          style={{
            backgroundColor: medErrors.includes(data.question_id)
              ? "#ffebeb"
              : "transparent",
            border: medErrors.includes(data.question_id)
              ? "1px solid #ff4d4d"
              : "1px solid #ddd",
            padding: "10px",
            borderRadius: "8px",
            transition: "all 0.3s ease-in-out",
            boxShadow: medErrors.includes(data.question_id)
              ? "0px 0px 3px rgba(255, 77, 77, 0.6)"
              : "none",
          }}
        >
          <FormControlLabel
            value={data.question_id}
            onChange={(e) => {
              const isChecked = e.target.checked;
              updateHDFCQuestion({ val: e.target.value });

              if (!isChecked) {
                setMedErrors((prevErrors) =>
                  prevErrors.filter((id) => id !== data.question_id)
                );
              }
            }}
            control={
              <Checkbox
                sx={{
                  color: medErrors.includes(data.question_id)
                    ? "#d32f2f"
                    : "#2959b8",
                  "&.Mui-checked": {
                    color: medErrors.includes(data.question_id)
                      ? "#d32f2f"
                      : "#2959b8",
                  },
                }}
              />
            }
            label={"Q. " + data.question}
            checked={HDFCcheckedQuestion.hasOwnProperty(data.question_id)}
            style={{
              color: medErrors.includes(data.question_id)
                ? "rgb(164 35 35)"
                : "#333",
              fontWeight: medErrors.includes(data.question_id) ? "600" : "400",
            }}
          />
        </div>
      </div>
    </>
  );
};

export const HDFCPersionIndividual = ({
  data,
  persion,
  pindex,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
}) => {
  const [selectedGender, setSelectedGender] = useState(
    localStorage.getItem("selectedType")
  );

  const updateHDFCPerson = (payload) => {
    const { question_id, pindex } = payload;
    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      if (!updatedState[question_id]) {
        updatedState[question_id] = {};
      }

      const question = updatedState[question_id];

      if (question.hasOwnProperty(pindex)) {
        delete question[pindex];
      } else {
        question[pindex] = [];
      }

      return updatedState;
    });
  };

  const check = () => {
    if (HDFCcheckedQuestion.hasOwnProperty(data.question_id)) {
      if (HDFCcheckedQuestion[data.question_id].hasOwnProperty(pindex)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setSelectedGender(localStorage.getItem("selectedType"));
  }, []);

  const allowedRelationsByGender = {
    M: ["Mother", "Grand Mother", "Sister", "Wife", "Mother In Law"],
    F: ["Mother", "Grand Mother", "Sister", "Wife", "Mother In Law", "Self"],
  };

  const allowedRelations = allowedRelationsByGender[selectedGender] || [];
  const personPresent = allowedRelations.includes(persion?.InsuredRelation);

  const getDisplayStyle = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const plan_id = params.get("plan_id");
    const c_id = params.get("c_id");

    const isQuestion6 = data.question_id == 6;
    const isHDFC = c_id == "hdfc";
    const isFamilyPlan = plan_id == "Family";
    const isIndividualPlan = plan_id == "Individual";

    if (isQuestion6 && isHDFC) {
      if (isFamilyPlan && !personPresent) {
        return "none";
      } else if (isIndividualPlan && !personPresent) {
        return "none";
      }
    }

    return "block";
  };

  return (
    <>
      <div
        className="same-section-med col-md-6"
        style={{ display: getDisplayStyle() }}
      >
        <div
          hidden={!HDFCcheckedQuestion.hasOwnProperty(data.question_id)}
          onClick={(e) => {
            updateHDFCPerson({
              pindex: pindex,
              question_id: data.question_id,
            });
          }}
          style={{ minWidth: "220px", cursor: "pointer" }}
        >
          <div
            className={
              check() ? "sumbox hdfc-sum col-md-12" : "sumbox hdfc-addon"
            }
            style={{ justifyContent: "center", minWidth: "250px" }}
          >
            <div>
              <span className="para">
                {proposerData?.InsuredDetail[pindex]?.InsuredName} (
                {persion?.InsuredRelation}){" "}
              </span>
            </div>
          </div>
        </div>

        {data?.get_child_question?.map((child_data) => {
          return (
            <SubQuestionAnswer
              key={child_data.question_id}
              data={child_data}
              parentData={data}
              pindex={pindex}
              proposerData={proposerData}
              HDFCcheckedQuestion={HDFCcheckedQuestion}
              setHDFCcheckedQuestion={setHDFCcheckedQuestion}
            />
          );
        })}
      </div>
    </>
  );
};

export const SubQuestionAnswer = ({
  data,
  pindex,
  parentData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
}) => {
  const proposalData = useSelector(selectProposal);

  const dispatch = useDispatch();

  const check = () => {
    if (HDFCcheckedQuestion.hasOwnProperty(parentData.question_id)) {
      if (HDFCcheckedQuestion[parentData.question_id].hasOwnProperty(pindex)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const [first, setFirst] = useState([]);

  useEffect(() => {
    const keysToCheck = [6, 6];
    const filteredObjects = keysToCheck
      .filter((key) => HDFCcheckedQuestion.hasOwnProperty(key))
      .map((key) => HDFCcheckedQuestion[key])
      .flat();

    setFirst(filteredObjects);
  }, [HDFCcheckedQuestion]);

  const getDeliveryDate = () => {
    if (first.length > 0) {
      return first[0];
    }
  };

  const datadate = getDeliveryDate();

  let deliveryDates = [];
  for (const key in datadate) {
    if (datadate.hasOwnProperty(key)) {
      const questions = datadate[key];

      questions.forEach((question) => {
        if (question.Options && question.Options.length > 0) {
          question.Options.forEach((option) => {
            if (option.DeliveryDate) {
              deliveryDates.push(option.DeliveryDate);
            }
          });
        }
      });
    }
  }

  function HDFCquestionExist(qa, qid) {
    for (let i = 0; i < qa.length; i++) {
      if (qa[i].QuestionId == qid) {
        return true;
        break;
      }
    }
    return false;
  }
  function HDFCgetAnswerSet(qdata, name, value, parentQid) {
    if (parentQid == "1") {
      return {
        OptionId: qdata.question_id,
        OptionText: qdata.question,
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        CurrentStatus: name == "CurrentStatus" ? value : "",
        LineOfManagement: name == "LineOfManagement" ? value : "",
        TreatmentDetails: name == "TreatmentDetails" ? value : "",
      };
    } else if (parentQid == "2") {
      if (qdata.question_id == "17") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
          CurrentStatus: name == "CurrentStatus" ? value : "",
          DiagnosisDate: name == "DiagnosisDate" ? value : "",
          SurgeryDate: name == "SurgeryDate" ? value : "",
          SurgeryDetails: name == "SurgeryDetails" ? value : "",
        };
      } else if (qdata.question_id == "18") {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          ExactDiagnosis: name == "",
          CurrentStatus: name == "",
          DiagnosisDate: name == "",
          SurgeryDate: name == "",
          SurgeryDetails: name == "",
        };
      }
    } else if (parentQid == "3") {
      if (qdata.question_id == "19") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
          DiagnosisDate: name == "DiagnosisDate" ? value : "",
          ConsultationDate: name == "ConsultationDate" ? value : "",
          CurrentStatus: name == "CurrentStatus" ? value : "",
          TreatmentDetails: name == "TreatmentDetails" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          ExactDiagnosis: name == "",
          DiagnosisDate: name == "",
          ConsultationDate: name == "",
          CurrentStatus: name == "",
          TreatmentDetails: name == "",
        };
      }
    } else if (parentQid == "4") {
      if (qdata.question_id == "21") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          TestFinding: name == "TestFinding" ? value : "",
          TestDate: name == "TestDate" ? value : "",
          TypeOfTest: name == "TypeOfTest" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          TestFinding: name == "",
          TestDate: name == "",
          TypeOfTest: name == "",
        };
      }
    } else if (parentQid == "5") {
      if (qdata.question_id == "23") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
          DiagnosisDate: name == "DiagnosisDate" ? value : "",
          ConsultationDate: name == "ConsultationDate" ? value : "",
          CurrentStatus: name == "CurrentStatus" ? value : "",
          TreatmentDetails: name == "TreatmentDetails" ? value : "",
          LineOfManagement: name == "LineOfManagement" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          ExactDiagnosis: name == "",
          DiagnosisDate: name == "",
          ConsultationDate: name == "",
          CurrentStatus: name == "",
          TreatmentDetails: name == "",
          LineOfManagement: name == "",
        };
      }
    } else if (parentQid == "6") {
      if (qdata.question_id == "25") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          DeliveryDate: name == "DeliveryDate" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          DeliveryDate: name == "",
        };
      }
    } else if (
      ["79", "80", "81", "82", "83", "84", "85", "86"].includes(parentQid)
    ) {
      if (parentQid == "85") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Visual / Hearing  disability",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: qdata.question,
        };
      }
    }
  }

  const updateHDFCQAlist = (payload) => {
    const { parentqData, pindex, qdata, name, value } = payload;
    const parentQid = parentqData.question_id;

    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      if (!updatedState[parentQid]) {
        updatedState[parentQid] = {};
      }

      const question = updatedState[parentQid];

      if (!question[pindex]) {
        question[pindex] = [];
      }

      const qa = question[pindex];

      const newObj = {
        QuestionId: parentqData.question_id,
        QuestionText: parentqData.question,
        Options: [HDFCgetAnswerSet(qdata, name, value, parentQid)],
      };

      if (!HDFCquestionExist(qa, parentQid)) {
        qa.push(newObj);
      } else {
        const objKey = qa.findIndex((item) => item.QuestionId == parentQid);
        const options = qa[objKey]["Options"];
        const optionIndex = options.findIndex(
          (item) => item.OptionId == qdata.question_id
        );

        if (optionIndex === -1) {
          options.push(HDFCgetAnswerSet(qdata, name, value, parentQid));
        } else {
          options[optionIndex][name] = value;
        }

        updatedState[parentQid][pindex] = qa
          .map((question) => ({
            ...question,
            Options: question.Options.filter((option) => {
              const keys = Object.keys(option).filter(
                (key) => key !== "OptionId" && key !== "OptionText"
              );
              return !keys.every((key) => option[key] === "");
            }),
          }))
          .filter((question) => question.Options.length > 0);
      }

      return updatedState;
    });
  };

  return (
    <>
      {!check() == false && data.question != "No" ? (
        <div
          className="ppv10 my-3 col-md-12"
          style={{
            flexWrap: "wrap",
            height: "auto",
            marginRight: "0px",
            minWidth: "248px",
            padding: "0px",
          }}
          hidden={
            ["79", "80", "81", "82", "83", "84", "85", "86"].includes(
              parentData.question_id
            )
              ? true
              : !check()
          }
        >
          <div
            className={
              parentData.question_id != "1"
                ? !["79", "80", "81", "82", "83", "84", "85", "86"].includes(
                    parentData.question_id
                  )
                  ? "col-md-12"
                  : "col-md-12 test-form-container addedClassHdfc"
                : "col-md-12 test-form-container"
            }
            style={{ marginRight: "7px" }}
          >
            <span
              className="nfh1 cash"
              style={{
                fontWeight: "600",
                display: data.question == "Yes" ? "none" : "block",
              }}
            >
              {data.question == "Yes"
                ? null
                : ["79", "80", "81", "82", "83", "84", "85", "86"].includes(
                    parentData.question_id
                  )
                ? `${data.question} added `
                : data.question}
            </span>
            {parentData.question_id == "1" ? (
              <AnswerSetOne
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "2" ? (
              <AnswerSetTwo
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "3" ? (
              <AnswerSetThree
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "4" ? (
              <AnswerSetFour
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "5" ? (
              <AnswerSetFive
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                deliverydate={deliveryDates}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "6" ? (
              <AnswerSetSeven
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                deliverydate={deliveryDates}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : ["79", "80", "81", "82", "83", "84", "85", "86"].includes(
                parentData.question_id
              ) ? (
              <AnswerSetSix
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                deliverydate={deliveryDates}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : null}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export const AnswerSetOne = ({
  pindex,
  qdata,
  parentqData,
  value,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const [dataObj, setDataObj] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [1];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let val;

      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex)) {
          let data_one = resultObjects[key][pindex];

          let val = data_one.find(
            (item) => item.QuestionId == parentqData.question_id
          );
          if (val != undefined) {
            let newobject = val;

            if (newobject) {
              newobject.Options.map((item, index) => {
                if (item.OptionId == qdata.question_id) {
                  setDataObj(item);
                  return;
                }
              });
            }
          }
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      <>
        <div className="hdfc-question-set col-md-12">
          <div className="upper-question-para col-md-6 pr-0">
            <TextField
              id="standard-helperText"
              className="col-md-12 hdfc-disease"
              label="Disease Name"
              value={dataObj?.ExactDiagnosis || ""}
              variant="standard"
              name="ExactDiagnosis"
              onChange={(e) => {
                updateHDFCQAlist({
                  name: "ExactDiagnosis",
                  value: e.target.value,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                });
              }}
            />
            <div className="hdfc-date-one ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DesktopDatePicker"]}
                  className="col-md-12"
                >
                  <DesktopDatePicker
                    className="hdfc-date"
                    variant="standard"
                    id="standard-helperText"
                    label="Date of Diagnosis"
                    value={
                      dataObj?.DiagnosisDate
                        ? dayjs(normalizeDate(dataObj?.DiagnosisDate))
                        : ""
                    }
                    format="DD-MM-YYYY"
                    name="Date of Diagnosis"
                    style={{ fontSize: "13px" }}
                    onChange={(e) => {
                      updateHDFCQAlist({
                        name: "DiagnosisDate",
                        value: getDate(e),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      });
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="hdfc-date-one ">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                className="setting-overflow"
              >
                <DemoContainer
                  components={["DesktopDatePicker"]}
                  className="col-md-12"
                >
                  <DesktopDatePicker
                    variant="standard"
                    className="hdfc-date"
                    id="standard-helperText"
                    label="Last Consultation Date"
                    value={
                      dataObj?.ConsultationDate
                        ? dayjs(normalizeDate(dataObj?.ConsultationDate))
                        : ""
                    }
                    format="DD-MM-YYYY"
                    name="Last Consultation Date"
                    style={{ fontSize: "13px" }}
                    onChange={(e) => {
                      updateHDFCQAlist({
                        name: "ConsultationDate",
                        value: getDate(e),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      });
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className="lower-question-para col-md-6 pr-0">
            <TextField
              className="hdfc-treatment"
              id="standard-helperText"
              label="Details of Treatment given"
              defaultValue=""
              variant="standard"
              name="Details of Treatment given"
              value={dataObj?.TreatmentDetails ? dataObj?.TreatmentDetails : ""}
              onChange={(e) => {
                updateHDFCQAlist({
                  name: "TreatmentDetails",
                  value: e.target.value,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                });
              }}
            />

            <TextField
              id="standard-select-currency-native"
              className="hdfc-select "
              select
              label=""
              SelectProps={{
                native: true,
              }}
              variant="standard"
              name="Current Status"
              value={dataObj?.CurrentStatus ? dataObj?.CurrentStatus : ""}
              onChange={(e) => {
                updateHDFCQAlist({
                  name: "CurrentStatus",
                  value: e.target.value,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                });
              }}
            >
              <option>Select Current Status</option>
              <option value="Cured">Cured</option>
              <option value="Not treated">Not treated</option>
              <option value="Ongoing">Ongoing</option>
            </TextField>
            <TextField
              id="standard-select-currency-native"
              className="hdfc-select"
              select
              label=""
              SelectProps={{
                native: true,
              }}
              variant="standard"
              name="Line of Management"
              value={dataObj?.LineOfManagement ? dataObj?.LineOfManagement : ""}
              onChange={(e) => {
                updateHDFCQAlist({
                  name: "LineOfManagement",
                  value: e.target.value,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                });
              }}
            >
              <option>Select Line of Management</option>
              <option value="Medical Management">Medical Management</option>
              <option value="Surgical Management">Surgical Management</option>
            </TextField>
          </div>
        </div>
      </>
    </>
  );
};

export const AnswerSetTwo = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();

  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [2];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        let data = resultObjects[0][pindex];

        if (data[0] == undefined) {
          return;
        }

        let newobject = data[0];

        if (newobject) {
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      <>
        <div className="test-form-container">
          <TextField
            id="type-of-test"
            label="Disease Name"
            variant="standard"
            name="ExactDiagnosis"
            value={dataObj?.Options[0]?.ExactDiagnosis || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "ExactDiagnosis",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <TextField
            id="standard-select-currency-native"
            className="test-input-field"
            select
            label=""
            SelectProps={{
              native: true,
            }}
            variant="standard"
            name="Current Status"
            value={
              dataObj?.Options[0]?.CurrentStatus
                ? dataObj?.Options[0]?.CurrentStatus
                : ""
            }
            onChange={(e) => {
              updateHDFCQAlist({
                name: "CurrentStatus",
                value: e.target.value,
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              });
            }}
          >
            <option>Select Current Status</option>
            <option value="Cured">Cured</option>
            <option value="Not treated">Not treated</option>
            <option value="Ongoing">Ongoing</option>
          </TextField>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DesktopDatePicker"]}
              className="col-md-12"
            >
              <DesktopDatePicker
                className="test-input-field"
                variant="standard"
                id="standard-helperText"
                label="Date of Diagnosis"
                value={
                  dataObj?.Options[0]?.DiagnosisDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.DiagnosisDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Date of Diagnosis"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  updateHDFCQAlist({
                    name: "DiagnosisDate",
                    value: getDate(e),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DesktopDatePicker"]}
              className="col-md-12"
            >
              <DesktopDatePicker
                className="test-input-field"
                variant="standard"
                id="standard-helperText"
                label="Date of Surgery"
                value={
                  dataObj?.Options[0]?.SurgeryDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.SurgeryDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Date of Surgery"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  updateHDFCQAlist({
                    name: "SurgeryDate",
                    value: getDate(e),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <TextField
            id="SurgeryDetails"
            label="Details of your past surgery"
            variant="standard"
            name="SurgeryDetails"
            value={dataObj?.Options[0]?.SurgeryDetails || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "SurgeryDetails",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};

export const AnswerSetThree = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();

  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [3];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        let data = resultObjects[0][pindex];

        if (data[0] == undefined) {
          return;
        }

        let newobject = data[0];

        if (newobject) {
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      <>
        <div className="test-form-container">
          <TextField
            id="standard-helperText"
            className="col-md-12 hdfc-disease"
            label="Disease Name"
            value={dataObj?.Options[0]?.ExactDiagnosis || ""}
            variant="standard"
            name="ExactDiagnosis"
            onChange={(e) => {
              updateHDFCQAlist({
                name: "ExactDiagnosis",
                value: e.target.value,
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              });
            }}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DesktopDatePicker"]}
              className="col-md-12"
            >
              <DesktopDatePicker
                className="test-input-field"
                variant="standard"
                id="standard-helperText"
                label="Date of Diagnosis"
                value={
                  dataObj?.Options[0]?.DiagnosisDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.DiagnosisDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Date of Diagnosis"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  updateHDFCQAlist({
                    name: "DiagnosisDate",
                    value: getDate(e),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            className="setting-overflow"
          >
            <DemoContainer
              components={["DesktopDatePicker"]}
              className="col-md-12"
            >
              <DesktopDatePicker
                variant="standard"
                className="test-input-field"
                id="standard-helperText"
                label="Last Consultation Date"
                value={
                  dataObj?.Options[0]?.ConsultationDate
                    ? dayjs(
                        normalizeDate(dataObj?.Options[0]?.ConsultationDate)
                      )
                    : ""
                }
                format="DD-MM-YYYY"
                name="Last Consultation Date"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  updateHDFCQAlist({
                    name: "ConsultationDate",
                    value: getDate(e),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          <TextField
            id="standard-select-currency-native"
            className="test-input-field"
            select
            label=""
            SelectProps={{
              native: true,
            }}
            variant="standard"
            name="Current Status"
            value={
              dataObj?.Options[0]?.CurrentStatus
                ? dataObj?.Options[0]?.CurrentStatus
                : ""
            }
            onChange={(e) => {
              updateHDFCQAlist({
                name: "CurrentStatus",
                value: e.target.value,
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              });
            }}
          >
            <option>Select Current Status</option>
            <option value="Cured">Cured</option>
            <option value="Not treated">Not treated</option>
            <option value="Ongoing">Ongoing</option>
          </TextField>

          <TextField
            id="standard-helperText"
            className="col-md-12 hdfc-disease"
            label="Treatment Details"
            value={dataObj?.Options[0]?.TreatmentDetails || ""}
            variant="standard"
            name="TreatmentDetails"
            onChange={(e) => {
              updateHDFCQAlist({
                name: "TreatmentDetails",
                value: e.target.value,
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              });
            }}
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};

export const AnswerSetFour = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [4];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        let data = resultObjects[0][pindex];

        if (data[0] == undefined) {
          return;
        }

        let newobject = data[0];

        if (newobject) {
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      <>
        <div className="test-form-container">
          <TextField
            id="test-finding"
            label="Test Finding"
            value={dataObj?.Options[0]?.TestFinding || ""}
            variant="standard"
            name="Test Finding"
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TestFinding",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="test-date-container">
              <FormLabel className="custom-label">Test Date</FormLabel>
              <DesktopDatePicker
                id="test-date"
                value={
                  dataObj?.Options[0]?.TestDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Test Date"
                className="test-date-picker"
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true,
                      className: "date-input-field",
                    },
                  },
                }}
                onChange={(e) =>
                  updateHDFCQAlist({
                    name: "TestDate",
                    value: getDate(e),
                    pindex,
                    qdata,
                    parentqData,
                  })
                }
              />
            </div>
          </LocalizationProvider>

          <TextField
            id="type-of-test"
            label="Type Of Test"
            variant="standard"
            name="Type Of Test"
            value={dataObj?.Options[0]?.TypeOfTest || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TypeOfTest",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};
export const AnswerSetFive = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();

  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [5];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        let data = resultObjects[0][pindex];

        if (data[0] == undefined) {
          return;
        }

        let newobject = data[0];

        if (newobject) {
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      <>
        <div className="test-form-container">
          <TextField
            id="Disease or Injury Details"
            label="Disease or Injury Details"
            variant="standard"
            name="Disease or Injury Details"
            value={dataObj?.Options[0]?.ExactDiagnosis || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "ExactDiagnosis",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DesktopDatePicker"]}
              className="col-md-12"
            >
              <DesktopDatePicker
                className="test-input-field"
                variant="standard"
                id="standard-helperText"
                label="Date of Diagnosis"
                value={
                  dataObj?.Options[0]?.DiagnosisDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.DiagnosisDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Date of Diagnosis"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  updateHDFCQAlist({
                    name: "DiagnosisDate",
                    value: getDate(e),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            className="setting-overflow"
          >
            <DemoContainer
              components={["DesktopDatePicker"]}
              className="col-md-12"
            >
              <DesktopDatePicker
                variant="standard"
                className="test-input-field"
                id="standard-helperText"
                label="Consultation Date"
                value={
                  dataObj?.Options[0]?.ConsultationDate
                    ? dayjs(
                        normalizeDate(dataObj?.Options[0]?.ConsultationDate)
                      )
                    : ""
                }
                format="DD-MM-YYYY"
                name="Last Consultation Date"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  updateHDFCQAlist({
                    name: "ConsultationDate",
                    value: getDate(e),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          <TextField
            id="standard-select-currency-native"
            className="test-input-field"
            select
            label=""
            SelectProps={{
              native: true,
            }}
            variant="standard"
            name="Current Status"
            value={
              dataObj?.Options[0]?.CurrentStatus
                ? dataObj?.Options[0]?.CurrentStatus
                : ""
            }
            onChange={(e) => {
              updateHDFCQAlist({
                name: "CurrentStatus",
                value: e.target.value,
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              });
            }}
          >
            <option>Select Current Status</option>
            <option value="Cured">Cured</option>
            <option value="Not treated">Not treated</option>
            <option value="Ongoing">Ongoing</option>
          </TextField>

          <TextField
            id="Treatment Details"
            label="Treatment Details"
            variant="standard"
            name="Treatment Details"
            value={dataObj?.Options[0]?.TreatmentDetails || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TreatmentDetails",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
          <TextField
            id="standard-select-currency-native"
            className="test-input-field"
            select
            label=""
            SelectProps={{
              native: true,
            }}
            variant="standard"
            name="Line of Management"
            value={
              dataObj?.Options[0]?.LineOfManagement
                ? dataObj?.Options[0]?.LineOfManagement
                : ""
            }
            onChange={(e) => {
              updateHDFCQAlist({
                name: "LineOfManagement",
                value: e.target.value,
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              });
            }}
          >
            <option>Select Line of Management</option>
            <option value="Medical Management">Medical Management</option>
            <option value="Surgical Management">Surgical Management</option>
          </TextField>
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};
export const AnswerSetSeven = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [6];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        let data = resultObjects[0][pindex];

        if (data[0] == undefined) {
          return;
        }

        let newobject = data[0];

        if (newobject) {
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      <>
        <div className="test-form-container">
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            className="setting-overflow"
          >
            <DemoContainer
              components={["DesktopDatePicker"]}
              className="col-md-12"
            >
              <DesktopDatePicker
                variant="standard"
                className="test-input-field"
                id="standard-helperText"
                label="Delivery Date"
                value={
                  dataObj?.Options[0]?.DeliveryDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.DeliveryDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Delivery Date"
                style={{ fontSize: "13px" }}
                onChange={(e) => {
                  updateHDFCQAlist({
                    name: "DeliveryDate",
                    value: getDate(e),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};
export const AnswerSetEight = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [6];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        let data = resultObjects[0][pindex];

        if (data[0] == undefined) {
          return;
        }

        let newobject = data[0];

        if (newobject) {
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  return (
    <>
      <Checkbox
        checked={
          dataObj?.Options[0] &&
          dataObj?.Options[0].OptionId == qdata.question_id
            ? true
            : false
        }
        // checked={checkedData}
        inputProps={{ "aria-label": "controlled" }}
        onChange={(e) => {
          updateHDFCQAlist({
            name: "data",
            value: e.target.checked,
            pindex: pindex,
            qdata: qdata,
            parentqData: parentqData,
          });
        }}
      />
    </>
  );
};

export const AnswerSetSix = ({
  pindex,
  qdata,
  parentqData,
  deliverydate,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const [dataObj, setDataObj] = useState();

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [79, 80, 81, 82, 83, 84, 85, 86];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        let data = resultObjects[0][pindex];

        if (data[0] == undefined) {
          return;
        }

        let newobject = data[0];

        if (newobject) {
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  useEffect(() => {
    if (
      ["79", "80", "81", "82", "83", "84", "85", "86"].includes(
        parentqData.question_id
      )
    ) {
      updateHDFCQAlist({
        name: "data",
        value: true,
        pindex: pindex,
        qdata: qdata,
        parentqData: parentqData,
      });
    }
  }, []);

  return (
    <>
      <Checkbox
        checked={dataObj?.Options[0] ? true : false}
        inputProps={{ "aria-label": "controlled" }}
      />
    </>
  );
};
