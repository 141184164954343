import React, { useState, useEffect } from "react";
import { AddonApi } from "../../../Api/Api";
import { IoPersonSharp } from "react-icons/io5";

const Hdfcmedical = (medicaldetails) => {
  const [hdfcQustions, setHdfcQustions] = useState([]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  let plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  // Fetch HDFC questions
  const getAddon = async () => {
    try {
      const response = await AddonApi(c_id, plan_id);
      const data = response.question_answer.data;
      const parsedData = data.map((item) => {
        if (typeof item === 'string') {
          return JSON.parse(item);
        } else {
          return item;
        }
      });
      setHdfcQustions(parsedData);
    } catch (error) {
      console.error("Error fetching addon data:", error);
    }
  };

  useEffect(() => {
    getAddon();
  }, [medicaldetails]);

  // Extract nested keys
  function getNestedKeys(obj) {
    let result = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === "object" && obj[key] !== null) {
        const nested = obj[key];
        for (let nestedKey in nested) {
          if (nested.hasOwnProperty(nestedKey) && typeof nested[nestedKey] === "object") {
            result[nestedKey] = nested[nestedKey];
          }
        }
      }
    }
    return result;
  }

  const medicalData = medicaldetails.medicaldetails[0];
  const nestedKeys = getNestedKeys(medicalData);
  const InsureDetailsList = medicaldetails?.userfetchdata?.proposal_data?.InsuredDetail;

  return (
    <div>
      {InsureDetailsList && Array.isArray(InsureDetailsList) ? (
        InsureDetailsList.map((data, index) => {
          const isIndexAvailable = Object.entries(nestedKeys).some(
            ([key, value]) => nestedKeys[key] && nestedKeys[key][index]
          );

          if (isIndexAvailable) {
            return (
              <div key={index}>
                <p className="insured-name"><IoPersonSharp/>&nbsp;{data.InsuredName}</p>
                {Object.entries(nestedKeys).map(([key, value]) => {
                  if (nestedKeys[key] && nestedKeys[key][index]) {
                    const matchedQuestion = hdfcQustions.find(
                      (item) => item.question_id === key
                    );

                    if (matchedQuestion) {
                        console.log("relionce,matchedQuestion",nestedKeys)
                      return (
                        <div key={key} className="mainicici">
                          <span className="qustionsbyID">
                            Q: <span className="mainqustion">{matchedQuestion.question}</span>
                          </span>
                          <br/>
                      

                          {nestedKeys[key][index].map((question, idx) => (
                            <div key={idx}>
                              {question.Options.map((option, optIdx) => {
                                const hasRelevantData =
                                  option.TestFinding ||
                                  option.TestDate ||
                                  option.TypeOfTest ||
                                  option.ConsultationDate ||
                                  option.CurrentStatus ||
                                  option.DiagnosisDate ||
                                  option.ExactDiagnosis ||
                                  option.LineOfManagement ||
                                  option.TreatmentDetails;

                                return hasRelevantData ? (
                                  <>
  
                                   {option.OptionText !== 'Yes' && (
                                      <span style={{ color: "#0265B3", fontSize: "11pt" }}>
                                        <b>Q</b>: {option.OptionText}
                                      </span>
                                    )}
                                    {option.OptionText && <br />}

                                    {option.TestFinding && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Test Finding: {option.TestFinding}
                                      </span>
                                    )}
                                    {option.TestFinding && <br />}

                                    {option.TestDate && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Test Date: {option.TestDate}
                                      </span>
                                    )}
                                    {option.TestDate && <br />}

                                    {option.TypeOfTest && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Type Of Test: {option.TypeOfTest}
                                      </span>
                                    )}
                                    {option.TypeOfTest && <br />}

                                    {option.ConsultationDate && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Consultation Date: {option.ConsultationDate}
                                      </span>
                                    )}
                                    {option.ConsultationDate && <br />}

                                    {option.CurrentStatus && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Current Status: {option.CurrentStatus}
                                      </span>
                                    )}
                                    {option.CurrentStatus && <br />}

                                    {option.DiagnosisDate && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Diagnosis Date: {option.DiagnosisDate}
                                      </span>
                                    )}
                                    {option.DiagnosisDate && <br />}

                                    {option.ExactDiagnosis && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Exact Diagnosis: {option.ExactDiagnosis}
                                      </span>
                                    )}
                                    {option.ExactDiagnosis && <br />}

                                    {option.LineOfManagement && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Line Of Management: {option.LineOfManagement}
                                      </span>
                                    )}
                                    {option.LineOfManagement && <br />}

                                    {option.TreatmentDetails && (
                                      <span style={{ color: "gray", fontSize: "11pt" }}>
                                        Treatment Details: {option.TreatmentDetails}
                                      </span>
                                    )}
                                    {option.TreatmentDetails && <br />}
                                  </>
                                ) : (
                                  <button className="isselectedicici" key={optIdx}>
                                    Selected
                                  </button>
                                );
                              })}
                            </div>
                          ))}
                        </div>
                      );
                    }
                  }
                  return null;
                })}
              </div>
            );
          }
          return null;
        })
      ) : (
        <p>No insured details available.</p>
      )}
    </div>
  );
};

export default Hdfcmedical;
