import React,{useEffect} from 'react'
import './privecypolicy.css'
import { Helmet } from 'react-helmet-async';
const Privacypolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
<div>
<Helmet>
        <title>Privacypolicy</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
    {/*Stection start */}
    <div className="margintermsnadpolicy"></div>
<div className="privecy">
<h1>Privecy <span style={{color:'red'}}>Policy*</span></h1>
<p>Guide2 Protect insurence Web Aggregator PVT.LTD</p>
</div>
<div className="privecyfooter">
<div className="undertext">


<div className="fixwidthDivtext">

<p className="normaltext">By registering on the website <a href="https://www.beema1.com">beema1.com</a> you authorize us, our affiliates & our associate partners to connect with you via email, phone call, or SMS and propose you with their services for the product you have opted for, imparting knowledge on the products, offering promotional discounts running on website & the offers are provided by the interrelated third parties, for which reasons, also recognizable as web aggregation, personally identifiable data, may be collected as detailed below. Check Your Premium Insurance Web Aggregator Limited (herein is referred as “Guide2protect”) is aware and interprets the importance information privacy shared by the user (“User”) at the time of visiting our website www.beema1.com  (“Website”). Considering the personal nature of the information, we have created this privacy policy (“Privacy Policy”) setting out the manner in which we shall use and protect the information. We have also put in place competent reformative measures and steps, to the best of our proficiencies, to ensure that the information shared, is not being used in unauthorized and unlawful or illegitimate manner. We shall use the information shared by the User solely for the purpose of offering a platform to the User for comparing the insurance policies available in the market and assisting the user to choose from the available policies, based on the User’s needs.</p>
<br />
<p className="normaltext"> 
Regardless of the fact that you have listed yourself under DND DNC or NCPR service, you still authorize and empower us to contact via a phone call from Beema1.com/Guide2protect Insurance Web Aggregator for the aforementioned intents and purposes of your registration with Beema1. This Privacy Policy covers Beema1’s treatment of personally identifiable information that Beema1 contains when you are on the website of Beema1, and when you use the services of Beema1.
</p>
<br />
<p className="normaltext">
This policy also covers Beema1’s treatment of any personally distinctive and specifically identifiable information that Beema1’s business partners share with Beema1. This policy does not apply to the practices of companies that Beema1 does not own or control or to people that Beema1 does not employ or regulate. Beema1 collects personally recognizable information when you register for a Beema1 account, when you use certain products or services from Beema1 and when you visit the Beema1 website pages, and when entering promotions. Beema1 may also receive personally identifiable information from our business partners. When you register with Beema1, we ask you to enter your first name, last name, state, city, email address, birth date, and gender. Once you register with your personal information with Beema1 and sign in to our services, you are not anonymous or unidentified to us. Also, during registration, you may be requested to register your mobile number and email ID, pager, or other devices to receive text messages, notifications, and other services to your wireless device. By registration, you authorize us to send SMS/email alerts to you for your login details and any other service requirements or some advertising messages/emails from us.
Being faithful and trustworthy is a major and significant angle of any relationship. With our clients, we are determined to build a great relationship and a long journey laying a foundation with a strong, systematic base, and a supportive backbone. Hence, your privacy and security are of ultimate importance to us.
</p>

</div>







<div className="fixwidthDivtext">
  <p className="normaltext">We practice to assure that your personal information is well-protected and remains confidential. </p>
<p className="normaltext">1. Only subject specific and content related information are collected from our customers. Information that is exclusively needed for an insurance policy or any other financial services or financial products we offer through our online platform.</p>
<p className="normaltext">2. Abiding the legal guidelines, we are sometimes required to share information or data with Government bodies or Government regulators. This is practised under appropriate supervision and strict authority.</p>
<p className="normaltext">3. At times, your information could be used for the improvement and betterment of our services and product offerings to our customers. </p>
<p className="normaltext">4. Our security systems are thoughtfully planned, technically sound, and has been designed in such a way that helps in preventing unlawful or inappropriate access.</p>
<br />
<br />
<p className="normaltext">
The summer season is best for devoting and providing the holy life for the devotee. In the winter, here are extremely beautiful as you get to experience the splendid beauty of snow-covered peaks, and the coziness this offers, in temperatures below 0 degrees. So, you have to plan your tour for summer, which is best from March to October.
</p>
</div>


<div className="fixwidthDivtext">
<h6>Statutory Compliance</h6>
<div className="yellowline"></div>
<p className="normaltext">We have created this Privacy Policy in compliance with Rule 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 ("Rules"), read with Section 43A of the Information Technology Act 2000 ("Act"), (as amended from time to time), which governs the scenarios wherein a body corporate or any individual on its behalf, collects, receives, possess, stores, deals or handle information of the provider of such information, is needed to provide a Privacy Policy for handling such information which includes personal and sensitive information. The Rules also provide that the Privacy Policy should be made conveniently understandable in a clear and easily accessible format available on the Website for view by the User.
</p>
</div>



<div className="fixwidthDivtext">

<h6>Gathering Information</h6>
<div className="yellowline"></div>
<p className="normaltext">The information collected by us can be, Personal Information (includes but is not limited to, name, age, address, contact information including email address). The User may, in certain scenarios and circumstances visit the Website anonymously, without disclosing the identity or without intending to provide or reveal any information. Notwithstanding this, there may be instances, wherein the User may be requested to furnish certain information in order to cater to the needs and requirements of registration and in order to avail the services of Beema1. We may use log files to source the information that includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyse trends, administer the site, track user's movement in the aggregate, and gather broad demographic information for aggregate use. Any such information so collected may also be used to enhance the quality of assistance offered, services we offer to you, to improve marketing, operations, analytics or site functionality.</p>
<br />
<br />
<p className="normaltext"><b>1.</b> At Beema1.com, we collect information through the online forms available on our company website or via mailing campaigns and telephonic conversations. The collected information is stored in a secure physical/electronic environment at all times</p>
<p className="normaltext"><b>2.</b> The use of cookies on your visit to our website may be registered and recorded for analysing the number of visitors to the site and general patterns of usage. Some of the information will be collected by "cookies" which are small bits of information that are automatically stored on a person's web browser in their computers or laptops. If you ever wish to disable these cookies, you may do so by changing or altering your browser settings.  </p>
</div>

<div className="fixwidthDivtext">
<h6>Storing Information:</h6>
<div className="yellowline"></div>
<p className="normaltext">The information collected by us can be, Personal Information (includes but is not limited to, name, age, address, contact information including email address). The User may, in certain scenarios and circumstances visit the Website anonymously, without disclosing the identity or without intending to provide or reveal any information. Notwithstanding this, there may be instances, wherein the User may be requested to furnish certain information in order to cater to the needs and requirements of registration and in order to avail the services of Beema1. We may use log files to source the information that includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyse trends, administer the site, track user's movement in the aggregate, and gather broad demographic information for aggregate use. Any such information so collected may also be used to enhance the quality of assistance offered, services we offer to you, to improve marketing, operations, analytics or site functionality.</p>
<br />
<br />
<p className="normaltext"><b>1.</b>Beema1.com uses 2048-bit SSL encryption.</p>
<p className="normaltext"><b>2.</b>Our servers are protected by firewalls to prevent any illegal or accidental access. </p>
<p className="normaltext"><b>3.</b>Physical, electronic, and managerial measures are intact to safeguard and secure all your personal information. We do train our employees with sound skills to handle customer information providing the highest levels of security.</p>
</div>


<div className="fixwidthDivtext">
<h6>Use of Information</h6>
<div className="yellowline"></div>
<p className="normaltext">The Information which is collected and stored is used only for lawful purposes which includes, creation of User account with the Website, that enables the User to manage and access multiple features of the Website. The information may be shared with partner insurers. By visiting the Website and giving us the missed call, you authorize and empower us and our partner insurers to call, send SMS, emails and newsletters to you notwithstanding you DND/DNC/NDCR.</p>
</div>

<br />
<div className="fixwidthDivtext">
<b className="normaltext">Permission with Consent</b>
<br />
<p className="normaltext">By accessing and providing the information whenever requested, the User permits with consents and grants Beema1.com the authority and permission to use the information in accordance with the Terms and Conditions and the Privacy Policy. The User acknowledges the subject to visit/registration on the Website and giving us the missed call irrespective of being recorded under DND/ DNC/ NDCR service, Check Your Premium and its related partners shall be authorized to provide updates through call, text messages, WhatsApp or similar app, emails and newsletters associated to the product information, unless concretely otherwise agreed by the User.</p>
</div>

<div className="fixwidthDivtext">
  <h6>Sharing Information</h6>
  <div className="yellowline"></div>
  <p className="normaltext">
We may share information with partner insurer, organizations or individuals (“Third Party”) outside of Beema1, if we have a good-faith that access, use, maintenance or disclosure of the information is reasonably necessary on 'need basis' to (a) meet any applicable law, regulation, legal process or implement governmental request; (b) enforce applicable Terms and Conditions, including investigation and inquiries of possible violations, (c) detect, prevent, or otherwise address fraud, security or technical issues; (d) protect and secure against harm or damages to the rights, property or safety of Beema1, the Users or the public as required or permitted by law; and (e) develop its business. The employees, consultants, representatives of Beema1 are bound by certain code of conduct and privacy and secrecy policy which obligates them to secure the Information with protectiveness.
  </p>
</div>
<div className="fixwidthDivtext">
  <p className="normaltext"><b>(1)&nbsp;</b>Information will be shared with our partners to fully serve your needs.</p>
  <p className="normaltext"><b>(2)&nbsp;</b>Your contact details will be used by us and our partners to connect with you</p>
  <p className="normaltext"><b>(3)&nbsp;</b>We may also need to contact you for other available products and services provided by Beema1. We may also share information that you provide with our trusted partners who may also contact you to offer additional products and services that may be of interest to you or may be useful to you and your family.</p>
  <p className="normaltext"><b>(4)&nbsp;</b>Personal information that is not needed by partners will not be shared.</p>
</div>

<div className="fixwidthDivtext">
  <p>For further clarifications, please write to us at</p>
  <a href="mailto:admin@beema1.com">admin@beema1.com</a>
  <br />
  <b className="normaltext">Safeguards</b>
  <p className="normaltext">
  Our attempt is to protect and secure the Information shared by the User and to ensure this, we have put in place appropriate technical and security measures to prevent unofficial or unlawful access to, accidental loss of, or detrimental and damage of the Information. However, the User fully understands and acknowledges that information transmitted to Beema1 may be subject to certain risk. The User is cautious of the limitations and agrees and guarantees not to hold Beema1 liable for any inadvertent access, loss or distortion of the Information. Additionally, you are culpabale for maintaining the confidentiality and security of your login id and password, and may not provide these credentials to any third party.
  </p>
  <b className="normaltext">External Links</b>
  <p className="normaltext">
  We may send to the User, other information about different products through newsletters, SMS updates, or e-mails (“Updates”). If the User does not wish to receive the Updates, the User can unsubscribe by sending an email to us at principal officer at admin@beema1.com. The method or process to unsubscribe may take a minimum of fifteen (15) working days. While visiting the Website, the User may come across links to other websites. The User shall be completely responsible for the User’s visit or association with those websites and its privacy policy. We are not responsible in any manner whatsoever for User's visit or association with such websites.
  </p>
  <b className="normaltext">Third Party Advertising</b>
  <p className="normaltext"> 
  We may use third-party advertising companies and/or advertisement agencies to serve advertisements when you visit our Website. These companies may use information (excluding your name, address, email address, or telephone number) about your visits to this Website in order to provide advertisements on this Website and other third-party websites about goods and services that may be a subject of interest to you or related to your requirements. We use third-party service providers to serve advertisements on our behalf across the internet and sometimes on this Website. They may collect anonymous information about your visits to Website, and your internet browsing related online interactions through the plethora of products and services on the Beema1 website. They may also use information about your visits to this and other Websites for targeted advertisements for products and services. This anonymous information is collected through the use of a pixel tag, which is industry standard technology used by most of the major Websites. No personally identifiable information is collected or used in this process.
  </p>
  </div>
  <div className="fixwidthDivtext">
    <h6>Types of Data Collected</h6>
    <div className="yellowline"></div>
    <p className="normaltext">
    Personal Data: While using Our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
    </p>
    <b className="normaltext">1.Email address</b>
    <br />
    <b className="normaltext">2.First name and last name</b>
    <br />
    <b className="normaltext">3.Phone number</b>
  </div>

  <div className="fixwidthDivtext">
    <h6>Data Usage:</h6>
    <div className="yellowline"></div>
    <p className="normaltext">
    Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
When You access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device You use, your mobile device's unique ID, the IP address of Your mobile device, your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
    </p>
    <br />
    <span className="normaltext">The Company may use Personal Data for the following purposes:</span>
    <br />
    <p className="normaltext"><b className="normaltext">1. &nbsp;</b>To provide, assist and maintain our Service, including monitoring the usage of our Service.</p>
    <p className="normaltext"><b className="normaltext">2. &nbsp;</b>To manage Your Account: To manage Your registration as a user of the Service, the Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
    <p className="normaltext"><b className="normaltext">3. &nbsp;</b>For the performance of a contract: The development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service</p>
    <p className="normaltext"><b className="normaltext">4. &nbsp;</b>To contact You via email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
    <p className="normaltext"><b className="normaltext">5. &nbsp;</b>To provide You with news, special offers, and general information about other goods, services, and events that we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
    <p className="normaltext"><b className="normaltext">7. &nbsp;</b>For business transfers: We may use Your information to evaluate or conduct a merger, disinvestment, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
    <p className="normaltext"><b className="normaltext">8. &nbsp;</b>For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the persuasiveness of our promotional campaigns, and evaluating, investigating and improving our Service, products, services, marketing, and your experience.</p>
<br />
<br />
<p>We may share Your personal information in the following situations:</p>
<br />
<p className="normaltext"><b className="normaltext">1.</b>With Service Providers: We may share Your personal information/data with Service Providers to monitor and analyse the use of our Service, and to contact You.</p>
<p className="normaltext"><b className="normaltext">2.</b>For business transfers: We may share or transfer Your personal information in connection with, or during agreements of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</p>
<p className="normaltext"><b className="normaltext">3.</b>With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with Us.</p>
<p className="normaltext"><b className="normaltext">4.</b>With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.</p>
<p className="normaltext"><b className="normaltext">5.</b>With other users: When you share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</p>
<p className="normaltext"><b className="normaltext">6.</b>With Your consent: We may disclose Your personal information for any other purpose with Your approval.</p>

  </div>
  <div className="fixwidthDivtext">
    <h6>Data Retention</h6>
    <div className="yellowline"></div>
    <p className="normaltext">We may retain Information about customers as per the logical requirements for the purposes of business and as required by government regulation for a suitable period. Our attempts to carefully dispose of records and delete Information after the expiration/termination of the data retention period. The Company will retain Your Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
    The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve and safeguard the functionality of Our Service, or We are legally obligated to retain this data for longer periods.</p>
  </div>

  <div className="fixwidthDivtext">
    <h6>Tracking Technologies and Cookies</h6>
    <div className="yellowline"></div>
    <p className="normaltext">Cookies are created and assembled when a browser loads a particular website. The website transmits information to the browser which then creates a text file. Every time, when the user visits the site, the user goes back to the same website, the browser retrieves and sends this file to the website's server. Computer Cookies are created not just by the website the user is browsing but also by other websites that run ads, widgets, or other elements on the page being loaded. These cookies regulate how the advertisements appear or how the widgets and other elements function on the page. Cookies can make use of the web easier by saving and administering status, application, predilections and another user information. Most browsers are initially set to accept cookies but the User can change the setting to refuse cookies or to be alerted when cookies are being sent. For instructions on how to change cookie settings, look in the Help menu on your Internet browser.</p>
  </div>

  <div className="fixwidthDivtext">
    <h6>Usage of Cookies</h6>
    <div className="yellowline"></div>
    <p className="normaltext">We may use cookies to (a) keep track of User status, business information and preferences of the User in relation to the Website or otherwise, (b) administer Website preferences and other information provided by the User visiting the Website, and (c) understand visitor usage, on an anonymous basis, of the Website.</p>
  </div>

  <div className="fixwidthDivtext">
    <h6>Cookies or Browser Cookies</h6>
    <div className="yellowline"></div>
    <p className="normaltext">A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if You do not accept Cookies, you may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse cookies, our Service may use Cookies.
Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
  </div>

  <div className="fixwidthDivtext">
    <b className="normaltext">We use both Session and Persistent Cookies for the purposes set out below:
    </b>
    <div className="yellowline"></div>
    <p className="normaltext">Necessary / Essential Cookies</p>
    <br />
    <p className="normaltext">Type: Session Cookies</p>
    <br />
    <p className="normaltext">Administered by Us</p>
    <br />
    <p className="normaltext"><b className="normaltext">Purpose:</b> These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
<br />
<b className="normaltext">Cookies Policy / Notice Acceptance Cookies</b>
<br />
<b className="normaltext">Type: Persistent Cookies</b>
<br />
<b className="normaltext">Administered by: Us</b>
<br />
<b className="normaltext">Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</b>
<br />
<b className="normaltext">Functionality Cookies</b>
<br />
<b className="normaltext">Type: Persistent Cookies</b>
<br />
<b className="normaltext">Administered by: Us</b>
<br />
<br />
<p className="normaltext">
<b className="normaltext">Purpose:</b> These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid having to re-enter your preferences every time You use the Website. For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.
</p>
<br />
<p className="normaltext">
<b className="normaltext">Amendment:</b> This Policy is subject to amendments from time to time. The changes are done with an endeavor to ensure better protection and is based on the decision taken by the management without giving any prior notice. By accessing the Website, the User agrees to these changes. The User may determine when the Privacy Policy was last updated by referring to the modification date found at the bottom of the Privacy webpage.
</p>
  </div>
  <div className="fixwidthDivtext">
    <b className="normaltext">Grievance Redressal</b>
    <br />
    <b className="normaltext">In the event of any security breach, Information theft or any provision of this Privacy Policy adversely affecting the interest of the User, the</b>
    <br />
    <b className="normaltext">User may write or contact us at:</b>
    <br />
    <b className="normaltext">Contact number: +91 9051911171</b>
    <br />
    <b className="normaltext">Email Id: admin@beema1.com</b>
    <br />
    <b className="normaltext">The Grievance Officer shall expeditiously within 20 days from the date of receipt of grievance, reply and attempt to resolve the same to the best of its knowledge.</b>
      </div>
</div>
</div>
</div>
  )
}

export default Privacypolicy